import React from "react";
import { IconSVGProps } from "types";

export function Icon(SVG: React.FunctionComponent<React.SVGProps<SVGSVGElement>>) {
  return function IconSVG(props?: IconSVGProps) {
    return <SVG {...props} />;
  };
}

export { default as IconAffiliate } from "./IconAffiliate";
export { default as IconStore } from "./IconStore";
export { default as IconGuarantee } from "./IconGuarantee";
