import { Col, Row } from "antd";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import AvatarProfile from "components/AvatarProfile";
import { Paragraph, Text } from "components/Typography";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactComponent as VideoIcon } from "public/static/assets/icons/video3.svg";
import React, { ReactNode, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { MODAL_CREATE_ACCOUNT, MODAL_LOGIN, toggleModalActions } from "redux/Modal/actions";
import { selectModalData } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { joinFanClubActions, logoutActions, setUserValueAction } from "redux/User/actions";
import { selectTalentCredential, selectUserData } from "redux/User/selector";
import { User } from "redux/User/types";
import { authService } from "services";
import { getTalentName } from "utils/experience";
import { userHasRole } from "utils/user";
import { ReactComponent as CategoryIcon } from "../../../public/static/assets/icons/category.svg";
import { ReactComponent as LogOutIcon } from "../../../public/static/assets/icons/logout.svg";
import { ReactComponent as PaymentIcon } from "../../../public/static/assets/icons/payment.svg";
import { ReactComponent as UserIcon } from "../../../public/static/assets/icons/user.svg";
import { ReactComponent as AddFillFullWidthIcon } from "../../../public/static/assets/icons/add-fill-full-width.svg";
import { SEGMENT_EVENT } from "constants/segment";
import { ROLES } from "constants/auth";
import useDeviceDetection from "hooks/useDeviceDetection";
import { useAnalytics } from "hooks/useAnalytics";
import usePage from "hooks/usePage";
import { komiConsumerUrl, komiTalentUrl } from "services/DomainService";
import { RequestAProfileModal } from "./RequestAProfileModal";
import { PAGE_TYPE } from "types";

interface IProps {
  placement?: "bottomRight" | "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter";
  icon?: ReactNode;
  setOpenDropdown?(status: boolean): void;
  overlayClassName?: string;
  showTalentProfileOnly?: boolean;
  talent?: Talent;
  isPreview?: boolean;
}

const DesktopMainMenu: React.FC<IProps> = ({
  icon,
  setOpenDropdown,
  placement = "bottomRight",
  overlayClassName = "",
  showTalentProfileOnly = false,
  isPreview,
}) => {
  const dispatch = useDispatch();
  const { sendSegmentEvent } = useAnalytics();

  const router = useRouter();
  const user: User = useTypedSelector(selectUserData);
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));
  const [requestNewProfileModalIsOpen, setRequestNewProfileModalIsOpen] = useState(false);
  const { type } = usePage({});
  const isHomepage = type === PAGE_TYPE.HOMEPAGE;

  const handleLogout = useCallback(() => {
    setOpenDropdown?.(false);
    dispatch(logoutActions.REQUEST());
    (window as any)?.analytics?.ready(function () {
      (window as any)?.analytics?.mixpanel?.cookie?.clear();
    });
    try {
      (window as any)?.FB?.api("/me/permissions", "delete", null, () =>
        (window as any)?.FB?.logout(),
      );
    } catch (error) {
      console.log();
    }
    if (router.asPath?.startsWith("/user/purchase-history/")) {
      router.push("/user/purchase-history/all");
    } else if (router.asPath?.startsWith("/user/special-requests/")) {
      router.push("/user/special-requests");
    }
  }, [dispatch, router, setOpenDropdown]);

  const handleSwitchToTalent = useCallback(() => {
    if (typeof window !== "undefined") {
      sendSegmentEvent(SEGMENT_EVENT.ACCESS_TALENT_PROFILE, {
        "Talent User Full Name": `${user?.talentProfile?.firstName} ${user?.talentProfile?.lastName}`,
        "Talent Profile ID": user?.talentProfile?.id,
        "Talent User ID": user?.id,
      });
      window.open(`${komiTalentUrl}/switch-to-talent`, "_blank");
    }
  }, [user, sendSegmentEvent]);

  const handleGotoAccountSetting = useCallback(() => {
    if (!user.talentProfile && !!user?.collaborationTalentProfiles?.length) {
      const collaborator = user.collaborationTalentProfiles[0];
      window.open(
        `${komiTalentUrl}/switch-to-talent?callbackUrl=/user/settings&talentProfileId=${collaborator?.talentProfile?.id}`,
        "_blank",
      );
      return;
    }
    window.open(`${komiTalentUrl}/switch-to-talent?callbackUrl=/user/settings`, "_blank");
  }, [user?.collaborationTalentProfiles, user?.talentProfile]);

  const handleGotoTalentByCollaborator = useCallback(
    (collaborator: User) => {
      if (collaborator?.talentProfile?.id) {
        sendSegmentEvent(SEGMENT_EVENT.ACCESS_TALENT_PROFILE, {
          "Talent User Full Name": `${user?.talentProfile?.firstName} ${user?.talentProfile?.lastName}`,
          "Talent Profile ID": user?.talentProfile?.id,
          "Talent User ID": user?.id,
        });
        window.open(
          `${komiTalentUrl}/collaborator-login?&talentProfileId=${collaborator.talentProfile.id}`,
          "_blank",
        );
      }
    },
    [sendSegmentEvent, user],
  );

  const toggleModalLogin = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: status,
          data: signUpModalData,
        }),
      );
    },
    [dispatch, signUpModalData],
  );

  const clickCreateProfile = useCallback(() => {
    window.open('/create-profile')
  }, []);

  const menu = (
    <Menu className={`main-menu ${showTalentProfileOnly && "main-menu--talent"}`}>
      {user ? (
        <>
          {!showTalentProfileOnly && (
            <>
              <Menu.Item icon={<UserIcon />} key="Account Information">
                <Link href="/user/account-information">
                  <a>Account Information</a>
                </Link>
              </Menu.Item>

              <Menu.Item icon={<PaymentIcon />} key="Payment History">
                <Link href="/user/purchase-history/all">
                  <a>Payment history</a>
                </Link>
              </Menu.Item>

              <Menu.Item icon={<CategoryIcon />} key="Upcoming">
                <Link href="/user/my-experiences/upcoming">
                  <a>Upcoming</a>
                </Link>
              </Menu.Item>

              <Menu.Item icon={<VideoIcon />} key="My Videos">
                <Link href="/user/videos">
                  <a>My Videos</a>
                </Link>
              </Menu.Item>
            </>
          )}

          {isHomepage && showTalentProfileOnly && (
            <>
              <Paragraph preset="semibold14" className="p__x--20 p__t--16 p__b--8 opacity--06">
                Connected Profiles
              </Paragraph>
            </>
          )}

          {isHomepage && user?.talentProfile && (
            <Menu.Item
              key={getTalentName(user, true)}
              icon={
                <AvatarProfile
                  avatarSize={24}
                  avatar={user?.talentProfile?.avatar || ""}
                  name={getTalentName(user, true)}
                />
              }
              onClick={handleSwitchToTalent}
            >
              <Text className="m__l--16 truncate" preset="medium16">
                {getTalentName(user, true)}
              </Text>
            </Menu.Item>
          )}

          {isHomepage &&
            user?.collaborationTalentProfiles?.map((collaborator, index) => (
              <Menu.Item
                key={index}
                icon={
                  <AvatarProfile
                    avatarSize={24}
                    avatar={collaborator.talentProfile?.avatar || ""}
                    name={getTalentName(collaborator, true)}
                  />
                }
                onClick={() => handleGotoTalentByCollaborator(collaborator)}
                // onClick={() => handleSwitchToTalent()}
              >
                <Text className="m__l--16" preset="medium16">
                  {getTalentName(collaborator, true)}
                </Text>
              </Menu.Item>
            ))}

          {userHasRole(user, ROLES.SELFSIGN) ? (
            <Menu.Item
              key={"/create-profile"}
              onClick={clickCreateProfile}
              className="large"
            >
              <Row align="middle">
                <Col className="d--flex align__items--center">
                  <AddFillFullWidthIcon className="no-stroke" />
                </Col>

                <Col>
                  <div className="d--flex flex__direction--column">
                    <Text preset="semibold16">Create a New Profile</Text>
                    <Text preset="semibold14" className="opacity--06">
                      30-day trial available
                    </Text>
                  </div>
                </Col>
              </Row>
            </Menu.Item>
          ) : null}

          {userHasRole(user, ROLES.TALENT) ? (
            <Menu.Item key="request-profile" onClick={() => setRequestNewProfileModalIsOpen(true)}>
              <Row align="middle">
                <Col className="d--flex align__items--center">
                  <AddFillFullWidthIcon className="no-stroke" />
                </Col>

                <Col>
                  <Text preset="semibold16">Request a New Profile</Text>
                </Col>
              </Row>
            </Menu.Item>
          ) : null}

          {showTalentProfileOnly ? (
            <>
              <Paragraph preset="semibold14" className="p__x--20 p__t--16 p__b--8 opacity--06">
                Account
              </Paragraph>

              <Menu.Item key="/user/settings" onClick={handleGotoAccountSetting}>
                <Text preset="semibold16">Account Information</Text>
              </Menu.Item>

              <Menu.Item key="logout" onClick={handleLogout}>
                <Text preset="semibold16">Log Out</Text>
              </Menu.Item>
            </>
          ) : (
            <Menu.Item
              className="text--red"
              icon={<LogOutIcon />}
              onClick={handleLogout}
              key="Logout"
            >
              <Text preset="medium16" className="text--red">
                Logout
              </Text>
            </Menu.Item>
          )}
        </>
      ) : (
        <>
          <Menu.Item
            key="login"
            className="p__x--0 m__y--0"
            onClick={() => {
              setOpenDropdown?.(false);
              toggleModalLogin(true);
            }}
          >
            <Text preset="medium16">{`Log In`}</Text>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <>
      <div>
        <Dropdown
          disabled={isPreview}
          overlay={menu}
          trigger={["click"]}
          placement={placement}
          overlayClassName={`main-menu__overlay ${overlayClassName}`}
          onVisibleChange={(visible) => {
            if (setOpenDropdown) {
              setOpenDropdown(visible);
            }
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            {icon || (
              <AvatarProfile
                avatarSize={32}
                avatar={user.avatar || ""}
                name={`${user.firstName} ${user.lastName}`}
              />
            )}
          </a>
        </Dropdown>
      </div>

      <RequestAProfileModal
        visible={requestNewProfileModalIsOpen}
        onCancel={() => setRequestNewProfileModalIsOpen(false)}
        onOk={() => setRequestNewProfileModalIsOpen(false)}
      />
    </>
  );
};

export default DesktopMainMenu;
