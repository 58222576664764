import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { SEGMENT_EVENT } from "constants/segment";
import { LandingPageSection } from "constants/landing-page";
import { MODAL_WAITLIST, toggleModalActions } from "redux/Modal/actions";
import { LandingPageV2Layout } from "./LandingPageV2Layout";
import { LandingSectionHero } from "./sections/LandingSectionHero";
import { LandingSectionEmpowerCreators } from "./sections/LandingSectionEmpowerCreators";
import { LandingSectionContainer } from "./sections/LandingSectionContainer";
import { LandingSectionFeatures } from "./sections/LandingSectionFeatures";
import { LandingSectionCategories } from "./sections/LandingSectionCategories";
import { LandingSectionFaq } from "./sections/LandingSectionFaq";
import { LandingSectionContact } from "./sections/LandingSectionContact";
import { AnalyticServices } from "utils/analytics";

function trackJoinWaitlistClick(position: string) {
  const properties = {
    location: AnalyticServices.getLocation(),
    platform: AnalyticServices.getPlatform({ side: "client-side" }),
    position: position,
  } as const;

  AnalyticServices.track(SEGMENT_EVENT.CLICK_JOIN_WAITLIST, {
    Location: properties.location,
    Platform: properties.platform,
    Position: properties.position,
  });
  AnalyticServices.track(SEGMENT_EVENT.GA_CLICK_JOIN_WAITLIST, {
    is_ga_only: true,
    location: properties.location,
    platform: properties.platform,
    position: properties.position,
  });
}

interface LandingPageV2Props {
  isMobile: boolean;
  isMobileDevice: boolean;
}

const LandingPageV2: React.FC<LandingPageV2Props> = ({ isMobile, isMobileDevice }) => {
  const [selectedSection, setSelectedSection] = useState<LandingPageSection | undefined>();
  const [activeSection, setActiveSection] = useState<LandingPageSection>("Komi");

  useEffect(() => {
    setSelectedSection(undefined);
  }, [activeSection]);

  const dispatch = useDispatch();
  const handleClickWaitlist = useCallback(
    (position: string) => {
      trackJoinWaitlistClick(position);
      dispatch(toggleModalActions({ modal: MODAL_WAITLIST, status: true, data: { position } }));
    },
    [dispatch],
  );

  return (
    <LandingPageV2Layout
      isMobile={isMobile}
      handleClickWaitlist={() => handleClickWaitlist("Nav Bar")}
      activeSection={activeSection}
      setSelectedSection={setSelectedSection}
    >
      <div className="landing-page-v2">
        <LandingSectionContainer
          sectionName="Komi"
          activeSection={selectedSection}
          setActiveSection={setActiveSection}
        >
          <LandingSectionHero
            isMobileLayout={isMobile || isMobileDevice}
            handleClickWaitlist={() => handleClickWaitlist("Landing Page Hero")}
          />
        </LandingSectionContainer>

        <section>
          <LandingSectionEmpowerCreators isMobile={isMobile} />
        </section>

        <LandingSectionContainer
          sectionName="Features"
          activeSection={selectedSection}
          setActiveSection={setActiveSection}
          intersectionThreshold={isMobile ? 0.2 : 0.6}
        >
          <LandingSectionFeatures isMobile={isMobile} />
        </LandingSectionContainer>

        <LandingSectionContainer
          sectionName="Creators"
          activeSection={selectedSection}
          setActiveSection={setActiveSection}
          scrollOffset={isMobile ? 40 : undefined}
        >
          <LandingSectionCategories isMobile={isMobile} />
        </LandingSectionContainer>

        <LandingSectionContainer
          sectionName="Join"
          activeSection={selectedSection}
          setActiveSection={setActiveSection}
          scrollOffset={isMobile ? 80 : 100}
        >
          <LandingSectionContact
            isMobile={isMobile}
            handleClickWaitlist={() => handleClickWaitlist("Landing Page Join")}
          />
        </LandingSectionContainer>

        <section>
          <LandingSectionFaq isMobile={isMobile} />
        </section>
      </div>
    </LandingPageV2Layout>
  );
};

export default LandingPageV2;
