import { ToValueOrError } from 'types'

export function parseContent(jwt: string | null): ToValueOrError<Record<string, any>> {
  let data: Record<string, any> | null = null
  let error = null

  try {
    // The error will be caught
    //@ts-ignore      
    data = JSON.parse(atob(jwt.split('.')[1]));
  } catch (e) {
    error = e
  }

  return [ error, data ]
}
