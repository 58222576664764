import React from "react";
import classNames from "classnames";
import { TalentCard } from "@komi-app/components";

import {
  landingPageHeroTalentsDesktop,
  landingPageHeroTalentsMobile,
} from "constants/landing-page";

interface HeroTalentBackgroundProps {
  isMobile: boolean;
}

export const HeroTalentBackground: React.FC<HeroTalentBackgroundProps> = ({ isMobile }) => {
  return (
    <div
      className={classNames("hero-talent-background", {
        "hero-talent-background--mobile": isMobile,
      })}
    >
      {(isMobile ? landingPageHeroTalentsMobile : landingPageHeroTalentsDesktop).map((talent) => (
        <div
          key={talent.name}
          className="hero-talent-background__talent"
          style={{
            left: `${talent.position[0]}%`,
            top: `${talent.position[1] + (isMobile ? 0 : 5)}%`,
            animationDelay: `${talent.animationOffset}s`,
          }}
        >
          <TalentCard imageUrl={`/static/assets/landing-page/${talent.bg}`} imageAlt={talent.name}>
            <img
              src={`/static/assets/landing-page/${talent.fg}`}
              alt={talent.name}
              className="hero-talent-background__talent__fg"
            />
          </TalentCard>
        </div>
      ))}
    </div>
  );
};
