import React, { useMemo, useState } from "react";
import classNames from "classnames";

import { customizeThemeImgs } from "constants/landing-page";
import { Hover3DImageStack } from "components/Hover3DImageStack";

interface ThemeFeatureProps {
  isMobile?: boolean;
}

export const ThemeFeature: React.FC<ThemeFeatureProps> = ({ isMobile }) => {
  const [selectedTheme, setSelectedTheme] = useState("dark");

  const hoverStack = useMemo(() => {
    return (
      <Hover3DImageStack
        images={customizeThemeImgs
          .map((theme) => {
            return theme.imgSrcs.map((imgSrc) => ({
              src: `/static/assets/landing-page/${imgSrc}`,
              offset: imgSrc.includes("-2") ? 3 : -1,
              className: classNames("theme-feature__graphic__img", {
                "theme-feature__graphic__img--selected": selectedTheme === theme.key,
              }),
            }));
          })
          .flat()}
        alt="Customize your theme"
      />
    );
  }, [selectedTheme]);

  return (
    <div className={classNames("theme-feature", { "theme-feature--mobile": isMobile })}>
      <div className="theme-feature__graphic">{hoverStack}</div>

      <ThemeColourSelector selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
    </div>
  );
};

interface ThemeColourSelectorProps {
  selectedTheme: string;
  setSelectedTheme: (theme: string) => void;
}

export const ThemeColourSelector: React.FC<ThemeColourSelectorProps> = ({
  selectedTheme,
  setSelectedTheme,
}) => {
  const options = [
    { key: "light", className: "theme-colour-selector__option--light" },
    { key: "dark", className: "theme-colour-selector__option--dark" },
    { key: "color", className: "theme-colour-selector__option--color" },
  ];

  return (
    <div className="theme-colour-selector">
      {options.map((option) => (
        <button
          key={option.key}
          className={classNames("theme-colour-selector__option", option.className, {
            "theme-colour-selector__option--selected": selectedTheme === option.key,
          })}
          onClick={() => setSelectedTheme(option.key)}
        >
          <span> </span>
        </button>
      ))}
    </div>
  );
};
