import { Col, Dropdown, Menu, Row } from "antd";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { Text, Paragraph } from "components/Typography";
import AvatarProfile from "components/AvatarProfile";
import { User, UserProfile } from "redux/User/types";
import { UserCallback, UserProfileCallback } from "types";
import { authService } from "services";
import { getTalentName } from "utils/experience";
import { Talent } from "redux/Talent/types";
import IconArrowDown from "components/@atoms/Icons/IconArrowDown";
import { useTypedSelector } from "redux/rootReducer";
import { selectUserData } from "redux/User/selector";
import { Use } from "types";
import React from "react";

export interface IProps {
  className?: string;
  disabled?: boolean;
  useTalent: Use<Talent>;
  useIsCollaborator: Use<boolean>;
  placeholder?: string;
  onProfileChange?: (talent: Talent, isCollaborator: boolean) => void;
}

const noop = () => {};

const ProfileSelection = ({
  className,
  disabled,
  useTalent,
  useIsCollaborator,
  placeholder,
  onProfileChange = noop,
}: IProps) => {
  const user = useTypedSelector(selectUserData);
  const [talent, setTalent] = useTalent || [];
  const [isCollaborator, setIsCollaborator] = useIsCollaborator || [];

  const onClickTalent = useCallback(
    (profile: UserProfile) => {
      if (typeof window !== "undefined") {
        const talent = profile as Talent;
        setTalent?.(talent);
        setIsCollaborator?.(false);
        onProfileChange(talent, false);
      }
    },
    [user],
  );

  const onClickCollaborator = useCallback(
    (collaborator: User) => {
      if (collaborator?.talentProfile?.id) {
        const talent = collaborator as Talent;
        setTalent?.(talent);
        setIsCollaborator?.(true);
        onProfileChange(talent, true);
      }
    },
    [user],
  );

  const profiles: ([UserProfile, UserProfileCallback, string?] | [User, UserCallback, string?])[] =
    [];

  if (user?.talentProfile) {
    profiles.push([user.talentProfile, onClickTalent, user?.talentProfile?.avatar]);
  }

  if (user?.collaborationTalentProfiles) {
    user.collaborationTalentProfiles.forEach((collaborator: User) => {
      profiles.push([collaborator, onClickCollaborator, collaborator?.talentProfile?.avatar]);
    });
  }

  const menu = useMemo(() => {
    return (
      <Menu className="profile-selection__item">
        {profiles.map(([user, onClick, avatar]) => (
          <Menu.Item
            key={getTalentName(user as Talent, true)}
            icon={
              <AvatarProfile
                avatarSize={24}
                avatar={avatar || ""}
                name={getTalentName(user as Talent, true)}
              />
            }
            onClick={() => onClick(user as any)}
          >
            <Text className="m__l--16" preset="medium16">
              {getTalentName(user as Talent, true)}
            </Text>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [profiles]);

  const currentLabel = talent ? getTalentName(talent, true) : placeholder || "Select a profile";

  if (profiles.length === 1) {
    const [user, onClick] = profiles[0];
    onClick(user as any);
  }

  return (
    <Dropdown
      className={classNames(
        "profile-selection m__t--8",
        className,
        disabled ? "cursor-not-allowed" : "cursor-pointer",
      )}
      overlay={menu}
      trigger={["click"]}
      disabled={disabled}
    >
      <Row
        align="middle"
        className="p__y--8 p__x--14 border-box bo__radius--8 cursor-pointer"
        justify="space-between"
        wrap={false}
      >
        <Col className="filter__label" flex={1}>
          <Paragraph ellipsis={{ rows: 1 }} preset="semibold16" className="m__r--10">
            {currentLabel}
          </Paragraph>
        </Col>
        <IconArrowDown />
      </Row>
    </Dropdown>
  );
};

export default ProfileSelection;
