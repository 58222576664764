import React from "react";
import classNames from "classnames";

import { landingPageMarqueeTalents } from "constants/landing-page";
import { InfiniteTalentMarquee } from "components/InfiniteTalentMarquee";
import { ReactComponent as TitleSupportingLeadingCreators } from "public/static/assets/landing-page/title-supporting-leading-creators.svg";
import { ReactComponent as TitleSupportingLeadingCreatorsMobile } from "public/static/assets/landing-page/title-supporting-leading-creators-mobile.svg";

interface LandingSectionCategoriesProps {
  isMobile?: boolean;
}

export const LandingSectionCategories: React.FC<LandingSectionCategoriesProps> = ({ isMobile }) => {
  return (
    <div
      className={classNames("landing-page-section-categories", {
        "landing-page-section-categories--mobile": isMobile,
      })}
    >
      <h1 className="landing-page-section-categories__heading">
        <span>Supporting leading creators with &gt;1 billion engaged fans</span>
        {isMobile ? <TitleSupportingLeadingCreatorsMobile /> : <TitleSupportingLeadingCreators />}
      </h1>

      <div className="landing-page-section-categories__content">
        <InfiniteTalentMarquee talents={landingPageMarqueeTalents.top} isMobile={isMobile} />

        {isMobile ? null : (
          <InfiniteTalentMarquee talents={landingPageMarqueeTalents.bottom} reverse />
        )}
      </div>
    </div>
  );
};
