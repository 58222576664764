import React from "react";
import classNames from "classnames";
import { CtaButton } from "@komi-app/components";

import { ReactComponent as TitleGetEverythingInOnePlace } from "public/static/assets/landing-page/title-get-everything-in-one-place.svg";
import { ReactComponent as TitleGetEverythingInOnePlaceMobile } from "public/static/assets/landing-page/title-get-everything-in-one-place-mobile.svg";

interface LandingSectionContactProps {
  isMobile?: boolean;
  handleClickWaitlist: () => void;
}

export const LandingSectionContact: React.FC<LandingSectionContactProps> = ({
  isMobile,
  handleClickWaitlist,
}) => {
  return (
    <div
      className={classNames("landing-page-section-contact", {
        "landing-page-section-contact--mobile": isMobile,
      })}
    >
      <div className="landing-page-section-contact__content">
        <div className="landing-page-section-contact__content__cta">
          <div className="landing-page-section-contact__content__cta__background"></div>

          <h1 className="landing-page-section-contact__content__cta__heading">
            <span>Get everything in one place</span>
            {isMobile ? <TitleGetEverythingInOnePlaceMobile /> : <TitleGetEverythingInOnePlace />}
          </h1>

          <p className="landing-page-section-contact__content__cta__description">
            Sign up today to unlock your Komi handle
          </p>

          <CtaButton size={isMobile ? "mobile" : "large"} onClick={handleClickWaitlist}>
            Join Waitlist
          </CtaButton>
        </div>
      </div>
    </div>
  );
};
