import React from "react";

import { TalentCard } from "@komi-app/components";

import { LandingPageTalentCard } from "constants/landing-page";
import classNames from "classnames";

interface InfiniteTalentMarqueeProps {
  talents: LandingPageTalentCard[];
  reverse?: boolean;
  isMobile?: boolean;
}

export const InfiniteTalentMarquee: React.FC<InfiniteTalentMarqueeProps> = ({
  talents,
  reverse,
  isMobile,
}) => {
  const style: any = { "--duration": `${talents.length * 3}s` };

  const marqueeGroup = (
    <div className="infinite-talent-marquee__group" style={style}>
      {talents.map((talent) => (
        <div className="infinite-talent-marquee__talent" key={talent.name}>
          <TalentCard
            imageUrl={`/static/assets/landing-page/${talent.bg}`}
            imageAlt={talent.name}
            komiLink={`https://${talent.komiUrl}`}
          >
            <img src={`/static/assets/landing-page/${talent.fg}`} alt={talent.name} />
          </TalentCard>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className={classNames("infinite-talent-marquee", {
        "infinite-talent-marquee--reverse": reverse,
        "infinite-talent-marquee--mobile": isMobile,
      })}
    >
      {marqueeGroup}
      {marqueeGroup}
    </div>
  );
};
