import React, {
  useState,
  memo,
  useCallback
} from 'react'

import Modal from 'components/Modal'
import {
  Button,
  Form,
  Input,
  FormInstance,
  Row
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Text } from 'components/Typography'
import { SHOPIFY_STORE_DOMAIN } from 'constants/regexp'
import ProfileSelection from './ProfileSelection'
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import { Talent } from 'redux/Talent/types'
import { To, Use } from 'types'
import { ShopifyModalOptions, ShopifyModalSelectedTalent } from 'types/shopify'

/**
 * will always be the last rendered setter
 * 
 * needs to be replaced by own dispatched action
 * for better disposal management.
 */
let setter: React.Dispatch<React.SetStateAction<symbol>> = () => {}

function trimProtocol (url?: string): string | undefined {
  return url && url.replace(/^http(s)?\:\/\//i, '')
}

class ModalControl {
  private _visible: boolean = false
  private _store?: string
  private promise?: Promise<ShopifyModalSelectedTalent>
  private resolve?: (value?: ShopifyModalSelectedTalent | PromiseLike<ShopifyModalSelectedTalent>) => void
  public talent?: Readonly<Talent>
  public isCollaborator?: Readonly<boolean>

  constructor () {
    // this.init()
    const {
      promise,
      resolve
    } = this.deferred()

    this.promise = promise
    this.resolve = resolve
  }

  private deferred () {
    let resolve
    const promise = new Promise<ShopifyModalSelectedTalent>(onSuccess => (resolve = onSuccess))

    return {
      promise,
      resolve
    }
  }

  private reload () {
    const {
      promise,
      resolve
    } = this.deferred()

    this.promise = promise
    this.resolve = resolve
  }

  // TODO: select optional step
  async open (options: ShopifyModalOptions): To<ShopifyModalSelectedTalent> {
    let error: any = null
    let selection: ShopifyModalSelectedTalent | null = null

    this.visible = true

    this._store = options.store // TODO: readonly state

    const { promise } = this

    // TODO: onOpen

    if (promise)
      return promise
        .then(value => {
          selection = value
          // TODO: onClose
        })
        .catch(e => error = e)
        .then(() => {
          this.visible = false
          this.reload()
          return [ error, selection ]
        })

    return [ new Error, null ]
  }

  close (value?: ShopifyModalSelectedTalent) {
    this.resolve?.(value)    
  }

  // TODO: previous, next, to, onStep

  get store () {
    return this._store
  }

  get visible () {
    return this._visible
  }

  private set visible (value: boolean) {
    this._visible = value
    const sym = Symbol()
    
    setter(sym)
  }
}

const modal = new ModalControl()

function useControl () {
  const [ sym, setSym ] = useState<symbol>(Symbol())
  setter = setSym // dirty trick, will be replaced by dispatcher

  return modal
}

const ConnectShopifyStoreModal = () => {
  const {
    modal,
    visible,
    form,
    useTalent,
    useIsCollaborator,
    onFinish,
    onProfileChange
  } = useSelf()

  return (<Modal
    closable
    centered
    title="Connect Shopify"
    visible={ visible }
    onCancel={ () => modal.close() }
    footer={ null }
    closeIcon={ <CloseIcon /> }
  >
    <Row className="m__b--8">
      <Text preset="regular14">Store domain</Text>
    </Row>
    <Form        
        form={ form }
        onFinish={ onFinish }
      >
        <Form.Item
          name="storeDomain"
          initialValue={ trimProtocol(modal.store) }
          rules={[
            {
              required: true,
              message: "Store domain is required",
            }
          ]}
        >
          <Input
            autoFocus
            disabled
            // name="storeDomain"
            placeholder="store.myshopify.com"
            value={ trimProtocol(modal.store) }
          />
        </Form.Item>
        <Form.Item
          name="selectedProfile"
          rules={[
            {
              required: true,
              message: "Please select a profile"
            }
          ]}
          >
          <Text preset="regular14">Connected profile</Text>
          <ProfileSelection
            useTalent={ useTalent }
            useIsCollaborator={ useIsCollaborator }
            onProfileChange={ onProfileChange }
          />
        </Form.Item>
        <Form.Item className="m__b--16">
          <Button
            className="text__transform--uppercase letter__spacing--1"
            type="primary"
            size="large"
            htmlType="submit"
            block
          >
            Connect store
          </Button>
        </Form.Item>
      </Form>
  </Modal>)
}

export function useSelf () {
  const modal = useControl()
  const { visible } = modal
  const [ form ] = useForm()
  const useTalent = useState<Talent>()
  const useIsCollaborator = useState<boolean>()

  const onFinish = useCallback(() =>
    modal.close({
      talent: form.getFieldValue('selectedProfile'),
      isCollaborator: form.getFieldValue('isCollaborator')
    }), [])

  const onProfileChange = useCallback((talent, isCollaborator) => {
    form.setFieldsValue({
      selectedProfile: talent,
      isCollaborator
    })
  }, [])

  return {
    modal,
    visible,
    form,
    useTalent,
    useIsCollaborator,
    onFinish,
    onProfileChange
  }
}

export default memo(ConnectShopifyStoreModal)
export {
  modal as ConnectShopifyStoreModalControl
}

ConnectShopifyStoreModal.modal = modal
