import { useEffect, useState } from "react";

export default function useDeviceDetection(): boolean {
  const [width, setWidth] = useState<number>(typeof window !== "undefined" ? window?.innerWidth : 1200);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileView: boolean = width <= 480;

  return isMobileView;
}
