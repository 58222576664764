import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Hover3DImageStack } from "components/Hover3DImageStack";
import { ThemeFeature } from "./components/ThemeFeature";
import { ReactComponent as TitleCustomizeYourHub } from "public/static/assets/landing-page/title-customize-your-hub.svg";
import { ReactComponent as TitleCustomizeYourHubMobile } from "public/static/assets/landing-page/title-customize-your-hub-mobile.svg";
import { ReactComponent as TitleEverythingInOnePlace } from "public/static/assets/landing-page/title-everything-in-one-place.svg";
import { ReactComponent as TitleEverythingInOnePlaceMobile } from "public/static/assets/landing-page/title-everything-in-one-place-mobile.svg";
import { ReactComponent as TitleOwnYourData } from "public/static/assets/landing-page/title-own-your-data.svg";
import { ReactComponent as TitleOwnYourDataMobile } from "public/static/assets/landing-page/title-own-your-data-mobile.svg";

interface LandingSectionFeaturesProps {
  isMobile?: boolean;
}

SwiperCore.use([Navigation]);

export const LandingSectionFeatures: React.FC<LandingSectionFeaturesProps> = ({ isMobile }) => {
  const sectionTheme = (
    <div className="landing-page-section-features__theme">
      <div className="landing-page-section-features__theme__graphic">
        <ThemeFeature isMobile={isMobile} />
      </div>

      <div className="landing-page-section-features__theme__copy">
        <h1 className="landing-page-section-features__theme__copy__heading">
          <span>Customize your hub</span>
          {isMobile ? <TitleCustomizeYourHubMobile /> : <TitleCustomizeYourHub />}
        </h1>

        <p className="landing-page-section-features__theme__copy__description">
          Truly personalize your landing page, connect all your existing channel partners, add your
          offerings to your profile and place as link-in-bio on your social channels
        </p>
      </div>
    </div>
  );

  const sectionEverything = (
    <div className="landing-page-section-features__everything">
      <div className="landing-page-section-features__everything__copy">
        <h1 className="landing-page-section-features__everything__copy__heading">
          <span>Everything in one place</span>
          {isMobile ? <TitleEverythingInOnePlaceMobile /> : <TitleEverythingInOnePlace />}
        </h1>

        <p className="landing-page-section-features__everything__copy__description">
          Komi have partnered with all the key platforms you need to integrate music, video, podcast
          and commerce. Easily consolidate all offerings
        </p>

        <img
          src="/static/assets/landing-page/everything-in-one-place-socials.svg"
          className="landing-page-section-features__everything__copy__socials"
          alt="Everything in one place"
        />
      </div>

      <div className="landing-page-section-features__everything__graphic">
        <Hover3DImageStack
          images={[
            { src: "/static/assets/landing-page/everything-in-one-place-1.webp", offset: -1 },
            { src: "/static/assets/landing-page/everything-in-one-place-2.webp", offset: 2 },
          ]}
          alt="Everything in one place"
        />
      </div>
    </div>
  );

  const sectionDataAnalytics = (
    <div className="landing-page-section-features__data-analytics">
      <div className="landing-page-section-features__data-analytics__graphic">
        <Hover3DImageStack
          images={[
            { src: "/static/assets/landing-page/data-analytics-1.webp", offset: -1 },
            { src: "/static/assets/landing-page/data-analytics-2.webp", offset: 2 },
            { src: "/static/assets/landing-page/data-analytics-3.webp", offset: 4 },
            { src: "/static/assets/landing-page/data-analytics-4.webp", offset: 1 },
            { src: "/static/assets/landing-page/data-analytics-5.webp", offset: 0 },
          ]}
          alt="Data and Analytics"
        />
      </div>

      <div className="landing-page-section-features__data-analytics__copy">
        <h1 className="landing-page-section-features__data-analytics__copy__heading">
          <span>Own your data & analytics</span>
          {isMobile ? <TitleOwnYourDataMobile /> : <TitleOwnYourData />}
        </h1>

        <p className="landing-page-section-features__data-analytics__copy__description">
          Access unique insights and analytics you can leverage for brand deals or brand strategy in
          your Komi hub
        </p>
      </div>
    </div>
  );

  return (
    <div className="landing-page-section-features">
      {isMobile ? (
        <div className="landing-page-section-features__mobile">
          <div className="landing-page-section-features__mobile__subsection">{sectionTheme}</div>
          <div className="landing-page-section-features__mobile__subsection">
            {sectionEverything}
          </div>
          <div className="landing-page-section-features__mobile__subsection">
            {sectionDataAnalytics}
          </div>
        </div>
      ) : (
        <Swiper loop simulateTouch={false} navigation={true}>
          <SwiperSlide>{sectionTheme}</SwiperSlide>
          <SwiperSlide>{sectionEverything}</SwiperSlide>
          <SwiperSlide>{sectionDataAnalytics}</SwiperSlide>
        </Swiper>
      )}
    </div>
  );
};
