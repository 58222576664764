import React, { useState } from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import {
  creatorsScreenshots,
  landingPageTalentCategories,
  LandingPageTalentCategoryName,
} from "constants/landing-page";
import { useInterval } from "hooks/useInterval";
import { ReactComponent as TitleBuiltToEmpowerCreators } from "public/static/assets/landing-page/title-built-to-empower-creators.svg";
import { ReactComponent as TitleBuiltToEmpowerCreatorsMobile } from "public/static/assets/landing-page/title-built-to-empower-creators-mobile.svg";

SwiperCore.use([Autoplay]);

interface LandingSectionEmpowerCreatorsProps {
  isMobile?: boolean;
}

export const LandingSectionEmpowerCreators: React.FC<LandingSectionEmpowerCreatorsProps> = ({
  isMobile,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<LandingPageTalentCategoryName>(
    landingPageTalentCategories[0].name,
  );
  const [skipNIntervals, setSkipNIntervals] = useState(0);

  const delay = 4000;

  useInterval(() => {
    if (isMobile) return;
    if (skipNIntervals > 0) {
      setSkipNIntervals(skipNIntervals - 1);
      return;
    }
    const active = landingPageTalentCategories.findIndex(
      (category) => category.name === selectedCategory,
    );
    const nextIndex = active === landingPageTalentCategories.length - 1 ? 0 : active + 1;
    setSelectedCategory(landingPageTalentCategories[nextIndex].name);
  }, delay);

  return (
    <div
      className={classNames("landing-page-section-empower-creators", {
        "landing-page-section-empower-creators--mobile": isMobile,
      })}
    >
      <h1 className="landing-page-section-empower-creators__heading">
        <span>Built to empower creators across all categories</span>
        {isMobile ? <TitleBuiltToEmpowerCreatorsMobile /> : <TitleBuiltToEmpowerCreators />}
      </h1>

      <div className="landing-page-section-empower-creators__content">
        {isMobile ? (
          <Swiper
            onSlideChange={(swiper) => {
              setSelectedCategory(landingPageTalentCategories[swiper.activeIndex].name);
            }}
            slideToClickedSlide={true}
            direction="vertical"
            className="landing-page-section-empower-creators__content__category-selector--mobile"
            centeredSlides={true}
            freeMode={true}
            freeModeSticky={true}
            freeModeMomentum={false}
            slidesPerView={5}
            height={200}
            autoplay={{
              delay,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
          >
            {landingPageTalentCategories.map((category, i) => (
              <SwiperSlide
                key={category.name}
                className="landing-page-section-empower-creators__content__category-selector--mobile__category"
              >
                {category.name}
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            <div>
              <div className="landing-page-section-empower-creators__content__category-selector">
                {landingPageTalentCategories.map((category) => (
                  <button
                    key={category.name}
                    onClick={() => {
                      setSkipNIntervals(2);
                      setSelectedCategory(category.name);
                    }}
                    className={classNames(
                      "landing-page-section-empower-creators__content__category-selector__category",
                      {
                        "landing-page-section-empower-creators__content__category-selector__category--selected":
                          selectedCategory === category.name,
                      },
                    )}
                  >
                    {category.name}
                  </button>
                ))}
              </div>

              <div className="landing-page-section-empower-creators__content__category-text">
                and many more...
              </div>
            </div>
          </>
        )}

        <div className="landing-page-section-empower-creators__content__graphic">
          <div className="landing-page-section-empower-creators__content__graphic__phone-container">
            <div className="landing-page-section-empower-creators__content__graphic__phone">
              {Object.entries(creatorsScreenshots).map(([category, screenshot]) => (
                <img
                  key={category}
                  src={`/static/assets/landing-page/${screenshot}`}
                  alt="Built to empower creators across all categories"
                  className={classNames(
                    "landing-page-section-empower-creators__content__graphic__phone__screenshot",
                    {
                      "landing-page-section-empower-creators__content__graphic__phone__screenshot--visible":
                        selectedCategory === category,
                    },
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
