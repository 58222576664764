import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { CtaButton, KomiLogo } from "@komi-app/components";
import { SEGMENT_EVENT } from "constants/segment";
import { useScrollPosition } from "hooks/useScrollPosition";
import { useAnalytics } from "hooks/useAnalytics";
import { selectModalData } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { setUserValueAction } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import { MODAL_CREATE_ACCOUNT, MODAL_LOGIN, MODAL_SIGNIN, toggleModalActions } from "redux/Modal/actions";
import DesktopMainMenu from "components/DesktopMainMenu/DesktopMainMenu";
import { useLandingAction } from "../../hooks/useLandingAction";

interface LandingPageV2HeaderProps {
  hide: boolean;
  isMobile: boolean;
  handleClickWaitlist?: () => void;
  onClickLogo?: () => void;
}

const LandingPageV2Header: React.FC<LandingPageV2HeaderProps> = ({
  hide,
  isMobile,
  handleClickWaitlist,
  onClickLogo,
}) => {
  const dispatch = useDispatch();
  const { sendSegmentEvent } = useAnalytics();
  const user = useTypedSelector(selectUserData);
  
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_SIGNIN));
  
  const [isScrolling, setIsScrolling] = useState(false);
  const [hideJoinWaitlist, setHideJoinWaitlist] = useState(true);

  const toggleModalLogin = useCallback(
    (status: boolean) => {
      dispatch(setUserValueAction({ error: null }));
      dispatch(
        toggleModalActions({ modal: MODAL_SIGNIN, status: status, data: signUpModalData }),
      );
    },
    [dispatch, signUpModalData],
  );

  useScrollPosition(
    ({ currPos }) => {
      setIsScrolling(currPos.y < -60);
      setHideJoinWaitlist(currPos.y > -500);
    },
    [],
    undefined,
    false,
    100,
  );

  useLandingAction(user, () => toggleModalLogin(true));

  const handleClickBtnLogin = useCallback(() => {
    toggleModalLogin(true);
    sendSegmentEvent(SEGMENT_EVENT.CLICK_LOGIN_BUTTON);
  }, [toggleModalLogin, sendSegmentEvent]);

  if (hide) return null;

  return (
    <div
      className={classNames(`landing-page-v2-layout__header__wrapper`, {
        scrolling: isScrolling,
      })}
    >
      <div className={isMobile ? "" : "landing__page-container padding"}>
        <div className={`landing-page-v2-layout__header ${isMobile && "p__x--24"}`}>
          <div></div>

          <div className="landing-page-v2-layout__header__logo" onClick={onClickLogo}>
            <KomiLogo color="primary-light" className="landing-page-v2-layout__header__logo" />
          </div>

          {!isMobile ? (
            <div className="landing-page-v2-layout__header__right">
              {!user && (
                <div
                  className={classNames("landing-page-v2-layout__header__right__join-waitlist", {
                    hide: hideJoinWaitlist,
                  })}
                >
                  <CtaButton onClick={handleClickWaitlist} disabled={!isScrolling}>
                    Join Waitlist
                  </CtaButton>
                </div>
              )}

              <div>
                {user ? (
                  isMobile ? null : (
                    <DesktopMainMenu
                      showTalentProfileOnly
                      overlayClassName="landing-page-v2-layout__menu"
                    />
                  )
                ) : (
                  <button
                    onClick={handleClickBtnLogin}
                    className="landing-page-v2-layout__header__right__login"
                  >
                    Login
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LandingPageV2Header;
