import { NextRouter } from 'next/router'
import React, {
  Dispatch
} from 'react'
import {
  User,
  UserProfile
} from 'redux/User/types'

export type IconSVGProps = {
  className?: string;
  stroke?: string;
  fill?: string;
  width?: number;
  height?: number;
  opacity?: number;
  style?: any;
}

export enum PAGE_TYPE {
  SSR = 0,
  HOMEPAGE = 1,
  TALENT = 2,
  CONTENT = 3,
  NOT_FOUND = 4,
}

export type LandingActionCallback = (
  user: unknown,
  urlParams: URLSearchParams,
  helpers: LandingActionHelper,
) => void

export type LandingActionItem = LandingActionCallback | {
  callback: LandingActionCallback,
  auth?: boolean
}

export type LandingActionHelper = {
  router: NextRouter
  dispatch: Dispatch<any>
}

export type Use<T = unknown> = [ T?, React.Dispatch<React.SetStateAction<T | undefined>>? ]

export type UserProfileCallback = (profile: UserProfile) => void
export type UserCallback = (user: User) => void
export type ToValue<T> = [ null, T ]
export type ToError<T> = [ T, null ]
export type ToValueOrError<TValue = unknown, TError = unknown> = ToValue<TValue> | ToError<TError> | [ TError, TValue ]
export type To<TValue = unknown, TError = unknown> = Promise<ToValueOrError<TValue, TError>>

