import { AvailablePeriod, Experience } from "redux/Experience/types";
import { IMAGE_REG } from "constants/regexp";
import moment from "moment";
import { Talent } from "redux/Talent/types";

export const getFirstAvatar = (experience: Experience | undefined): string => {
  if (!experience) return "";

  let result = "";

  if (!experience.covers) return result;

  for (const val of experience.covers) {
    if (IMAGE_REG.test(val)) {
      result = val;
      break;
    }
  }

  return result;
};

export const getAvatarName = (name: string) => {
  if (name) {
    const elements = name.split(" ");
    return elements.length > 0
      ? elements.map((item) => (item.length ? item[0].toUpperCase() : "")).join("")
      : name[0];
  }
  return "";
};

export const getTalentName = (talent: Talent | undefined, useTalentName = false) => {
  if (!talent) return "";
  const displayName = talent?.talentProfile?.displayName?.trim();
  const talentName = `${talent?.talentProfile?.firstName || ""} ${
    talent?.talentProfile?.lastName || ""
  }`;

  return displayName?.length && !useTalentName ? displayName : talentName;
};

export const getTruncatedTalentName = (talent: Talent | undefined, useTalentName = false) => {
  const talentName = getTalentName(talent, useTalentName);
  if (talentName.length > 24) return `${talentName.slice(0, 24)}...`;
  return talentName;
};

export const sortExperiencesByDate = (experiences: Experience[] | undefined = []) => {
  let scheduleList = [...experiences]?.filter((experience) => experience?.scheduleTime);
  let createdAtList = [...experiences]?.filter((experience) => !experience?.scheduleTime);

  scheduleList = scheduleList?.sort((experienceA, experienceB) => {
    const dateA = moment(experienceA?.scheduleTime);
    const dateB = moment(experienceB?.scheduleTime);

    return dateA.isBefore(dateB) ? -1 : 1;
  });

  createdAtList = createdAtList?.sort((experienceA, experienceB) => {
    const dateA = moment(experienceA?.createdAt);
    const dateB = moment(experienceB?.createdAt);

    return dateA.isBefore(dateB) ? -1 : 1;
  });

  return [...scheduleList, ...createdAtList];
};

export enum EXCLUSIVE_PRICE {
  POA = -1,
}

export const POA_EXPERIENCE = ({
  name,
  cover,
  firstName,
  lastName,
  avatar,
  id,
}: {
  name: string | undefined;
  cover: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  avatar: string | undefined;
  id: number | undefined;
}): any => {
  return {
    id: "",
    covers: [cover],
    name,
    type: "one_to_one",
    creator: {
      talentProfile: {
        id,
        firstName,
        lastName,
        avatar,
      },
    },
    isPOA: true,
  };
};

export const DSTTimezoneHandler = (
  availablePeriods: AvailablePeriod[],
  userSelectedTimeZone: string,
  talentSelectedTimezone: string,
) => {
  const mappedPeriods = availablePeriods
    .reverse()
    .map((period) => {
      return {
        ...period,
        isDSTStartUser: moment(period.start).tz(userSelectedTimeZone).isDST(),
        isDSTEndUser: moment(period.end).tz(userSelectedTimeZone).isDST(),
        localPeriodUser: `${moment(period.start)
          .tz(userSelectedTimeZone)
          .format("DD/MM/YYYY HH:mm")} - ${moment(period.end)
          .tz(userSelectedTimeZone)
          .format("DD/MM/YYYY HH:mm")}`,
        isDSTStartTalent: moment(period.start).tz(talentSelectedTimezone).isDST(),
        isDSTEndTalent: moment(period.end).tz(talentSelectedTimezone).isDST(),
        localPeriodTalent: `${moment(period.start)
          .tz(talentSelectedTimezone)
          .format("DD/MM/YYYY HH:mm")} - ${moment(period.end)
          .tz(talentSelectedTimezone)
          .format("DD/MM/YYYY HH:mm")}`,
      };
    })
    .filter(
      (period) =>
        period.isDSTStartUser === period.isDSTEndUser &&
        period.isDSTStartTalent === period.isDSTEndTalent,
    );
  const result: any[] = [];
  for (const period of mappedPeriods) {
    if (
      !result.some(
        (item) =>
          item.localPeriodUser === period.localPeriodUser ||
          item.localPeriodTalent === period.localPeriodTalent,
      )
    ) {
      result.push(period);
    }
  }
  return result
    .reverse()
    .map((period: AvailablePeriod) => ({ id: period.id, start: period.start, end: period.end }));
};
