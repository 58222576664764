import { useCallback } from "react";
import { AnalyticServices } from "utils/analytics";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { KOMI_USER_LOCATION } from "services/UserService";
import { SEGMENT_EVENT } from "constants/segment";

export const useAnalytics = () => {
  const sendSegmentEvent = useCallback((eventName: SEGMENT_EVENT, data = {}) => {
    AnalyticServices.track(eventName, {
      ...data,
      Location: Cookies.get(KOMI_USER_LOCATION),
      Platform: isMobile ? "Responsive" : "Web",
    });
  }, []);
  return { sendSegmentEvent };
};
