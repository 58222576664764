import React, { useEffect, useRef, useState } from "react";

import useIntersection from "hooks/useIntersection";
import { LandingPageSection } from "constants/landing-page";

interface LandingSectionContainerProps {
  setActiveSection: (newSection: LandingPageSection) => void;
  sectionName: LandingPageSection;
  activeSection?: LandingPageSection;
  noScroll?: boolean;
  scrollOffset?: number;
  intersectionThreshold?: number;
}

export const LandingSectionContainer: React.FC<LandingSectionContainerProps> = ({
  setActiveSection,
  sectionName,
  activeSection,
  noScroll,
  scrollOffset,
  intersectionThreshold,
  children,
}) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: intersectionThreshold || 0.6,
  });

  useEffect(() => {
    if (intersection?.isIntersecting !== isIntersecting) {
      setIsIntersecting(intersection?.isIntersecting ?? false);
    }
  }, [intersection, isIntersecting]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isIntersecting) setActiveSection(sectionName);
    }, 50);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    if (activeSection === sectionName && !noScroll) {
      if (scrollOffset && sectionRef.current) {
        // If scroll offset is set, scroll with offset
        const top = sectionRef.current.getBoundingClientRect().top;
        window.scrollTo({ top: top + window.pageYOffset - scrollOffset, behavior: "smooth" });
      } else {
        // Otherwise, scroll to top of section
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection, sectionName]);

  return <section ref={sectionRef}>{children}</section>;
};
