import React, { useState } from "react";
import classNames from "classnames";
import { ExpandableSection } from "@komi-app/components";

import { ReactComponent as TitleFaqs } from "public/static/assets/landing-page/title-faqs.svg";

interface LandingSectionFaqProps {
  isMobile?: boolean;
}

export const LandingSectionFaq: React.FC<LandingSectionFaqProps> = ({ isMobile }) => {
  const [activeQuestion, setActiveQuestion] = useState<string | undefined>(undefined);

  const questions = [
    {
      title: "What is Komi?",
      content: (
        <>
          <p>
            Komi was started to empower creators across the world with access to multiple core products and tools they
            need, in one platform.
          </p>
          <p>
            Komi’s first product is the world’s most powerful landing page - purpose-built for creators with all of the
            integrations and partnerships you would need. We make it simple for you to bring all of your offerings into
            your personalized ‘home on the internet’. Komi pages are all drag and drop so require no technical skill!
          </p>
        </>
      ),
    },
    {
      title: "Why should I join Komi?",
      content: (
        <>
          <p>
            Komi makes it easy for you to bring everything you do into one, fully-personalized hub that can sit across
            your social channels as link-in-bio.
          </p>
          <p>
            Creators often generate income from multiple different sources (tickets, brand deals, podcasts, merch,
            music, etc), but these offerings are spread all over the internet. By having a single-hub it creates a much
            improved viewing experience for fans, whilst providing you more upsell/cross-sell and data / analytics.
          </p>
          <p>We are already connecting more than a billion followers to creators across the Komi platform.</p>
        </>
      ),
    },
    {
      title: "How do I claim my Komi handle / domain?",
      content: (
        <>
          <p>Sign up to our waitlist to receive early access to secure your Komi handle in mid January 2023</p>
        </>
      ),
    },
  ];

  return (
    <div
      className={classNames("landing-page-section-faq", {
        "landing-page-section-faq--mobile": isMobile,
      })}
    >
      <div className="landing-page-section-faq__content">
        <h1 className="landing-page-section-faq__content__heading">
          <span>FAQs</span>
          <TitleFaqs />
        </h1>

        <div className="landing-page-section-faq__content__faqs">
          {questions.map((question) => (
            <ExpandableSection
              key={question.title}
              title={question.title}
              isOpen={question.title === activeQuestion}
              onToggle={(isOpen) => setActiveQuestion(isOpen ? question.title : undefined)}
            >
              {question.content}
            </ExpandableSection>
          ))}
        </div>

        <div className="landing-page-section-faq__content__link">
          <a href="https://support.komi.io" target="_blank" rel="noopener noreferrer">
            Read_all_FAQ
          </a>
        </div>
      </div>
    </div>
  );
};
