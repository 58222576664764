export interface LandingPageTalentCard {
  name: string;
  bg: string;
  fg: string;
  komiUrl: string;
}

export const landingPageTalents: { [key: string]: LandingPageTalentCard } = {
  addisonRae: {
    name: "Addison Rae",
    bg: "addison-rae-card-bg.webp",
    fg: "addison-rae-card-fg.svg",
    komiUrl: "addisonrae.komi.io",
  },
  ashleyGraham: {
    name: "Ashley Graham",
    bg: "ashley-graham-card-bg.webp",
    fg: "ashley-graham-card-fg.svg",
    komiUrl: "ashleygraham.komi.io",
  },
  charlesLeclerc: {
    name: "Charles Leclerc",
    bg: "charles-leclerc-card-bg.webp",
    fg: "charles-leclerc-card-fg.svg",
    komiUrl: "charlesleclerc.komi.io",
  },
  courteneyCox: {
    name: "Courteney Cox",
    bg: "courteney-cox-card-bg.webp",
    fg: "courteney-cox-card-fg.svg",
    komiUrl: "courteneycox.komi.io",
  },
  deAndreHopkins: {
    name: "DeAndre Hopkins",
    bg: "deandre-hopkins-card-bg.webp",
    fg: "deandre-hopkins-card-fg.svg",
    komiUrl: "deandrehopkins.komi.io",
  },
  disclosure: {
    name: "Disclosure",
    bg: "disclosure-card-bg.webp",
    fg: "disclosure-card-fg.svg",
    komiUrl: "disclosure.komi.io",
  },
  eltonJohn: {
    name: "Elton John",
    bg: "elton-john-card-bg.webp",
    fg: "elton-john-card-fg.svg",
    komiUrl: "eltonjohn.komi.io",
  },
  evaLongoria: {
    name: "Eva Longoria",
    bg: "eva-longoria-card-bg.webp",
    fg: "eva-longoria-card-fg.svg",
    komiUrl: "evalongoria.komi.io",
  },
  farruko: {
    name: "Farruko",
    bg: "farruko-card-bg.webp",
    fg: "farruko-card-fg.svg",
    komiUrl: "farruko.komi.io",
  },
  gwenStefani: {
    name: "Gwen Stefani",
    bg: "gwen-stefani-card-bg.webp",
    fg: "gwen-stefani-card-fg.svg",
    komiUrl: "gwenstefani.komi.io",
  },
  idrisElba: {
    name: "Idris Elba",
    bg: "idris-elba-card-bg.webp",
    fg: "idris-elba-card-fg.svg",
    komiUrl: "idriselba.komi.io",
  },
  jackWhitehall: {
    name: "Jack Whitehall",
    bg: "jack-whitehall-card-bg.webp",
    fg: "jack-whitehall-card-fg.svg",
    komiUrl: "jackwhitehall.komi.io",
  },
  joeJonas: {
    name: "Joe Jonas",
    bg: "joe-jonas-card-bg.webp",
    fg: "joe-jonas-card-fg.svg",
    komiUrl: "joejonas.komi.io",
  },
  jonasBrothers: {
    name: "Jonas Brothers",
    bg: "jonas-brothers-card-bg.webp",
    fg: "jonas-brothers-card-fg.svg",
    komiUrl: "jonasbrothers.komi.io",
  },
  jonathanVanNess: {
    name: "Jonathan Van Ness",
    bg: "jonathan-van-ness-card-bg.webp",
    fg: "jonathan-van-ness-card-fg.svg",
    komiUrl: "jvn.komi.io",
  },
  lilyCollins: {
    name: "Lily Collins",
    bg: "lily-collins-card-bg.webp",
    fg: "lily-collins-card-fg.svg",
    komiUrl: "lilycollins.komi.io",
  },
  lizzo: {
    name: "Lizzo",
    bg: "lizzo-card-bg.webp",
    fg: "lizzo-card-fg.svg",
    komiUrl: "lizzo.komi.io",
  },
  masonMount: {
    name: "Mason Mount",
    bg: "mason-mount-card-bg.webp",
    fg: "mason-mount-card-fg.svg",
    komiUrl: "masonmount.komi.io",
  },
  matthewMcconaughey: {
    name: "Matthew McConaughey",
    bg: "matthew-mcconaughey-card-bg.webp",
    fg: "matthew-mcconaughey-card-fg.svg",
    komiUrl: "matthewmcconaughey.komi.io",
  },
  meghanTrainor: {
    name: "Meghan Trainor",
    bg: "meghan-trainor-card-bg.webp",
    fg: "meghan-trainor-card-fg.svg",
    komiUrl: "meghantrainor.komi.io",
  },
  oliviaPalermo: {
    name: "Olivia Palermo",
    bg: "olivia-palermo-card-bg.webp",
    fg: "olivia-palermo-card-fg.svg",
    komiUrl: "oliviapalermo.komi.io",
  },
  patrickStarrr: {
    name: "Patrick Starrr",
    bg: "patrick-starrr-card-bg.webp",
    fg: "patrick-starrr-card-fg.svg",
    komiUrl: "patrickstarrr.komi.io",
  },
  robGronkowski: {
    name: "Rob Gronkowski",
    bg: "rob-gronkowski-card-bg.webp",
    fg: "rob-gronkowski-card-fg.svg",
    komiUrl: "robgronkowski.komi.io",
  },
  sabrinaCarpenter: {
    name: "Sabrina Carpenter",
    bg: "sabrina-carpenter-card-bg.webp",
    fg: "sabrina-carpenter-card-fg.svg",
    komiUrl: "sabrinacarpenter.komi.io",
  },
  stormReid: {
    name: "Storm Reid",
    bg: "storm-reid-card-bg.webp",
    fg: "storm-reid-card-fg.svg",
    komiUrl: "stormreid.komi.io",
  },
  taylorHill: {
    name: "Taylor Hill",
    bg: "taylor-hill-card-bg.webp",
    fg: "taylor-hill-card-fg.svg",
    komiUrl: "taylorhill.komi.io",
  },
  tomKerridge: {
    name: "Tom Kerridge",
    bg: "tom-kerridge-card-bg.webp",
    fg: "tom-kerridge-card-fg.svg",
    komiUrl: "tomkerridge.komi.io",
  },
  u2: {
    name: "U2",
    bg: "u2-card-bg.webp",
    fg: "u2-card-fg.svg",
    komiUrl: "u2.komi.io",
  },
  usher: {
    name: "Usher",
    bg: "usher-card-bg.webp",
    fg: "usher-card-fg.svg",
    komiUrl: "usher.komi.io",
  },
  willowSmith: {
    name: "Willow Smith",
    bg: "willow-smith-card-bg.webp",
    fg: "willow-smith-card-fg.svg",
    komiUrl: "willowsmith.komi.io",
  },
};

interface LandingPageHeroTalent extends LandingPageTalentCard {
  animationOffset: number;
  // position is [left, top] distance in %
  position: number[];
}

export const landingPageHeroTalentsDesktop: LandingPageHeroTalent[] = [
  {
    ...landingPageTalents.sabrinaCarpenter,
    animationOffset: 0,
    position: [6, 13],
  },
  {
    ...landingPageTalents.gwenStefani,
    animationOffset: -12,
    position: [22, 0],
  },
  {
    ...landingPageTalents.deAndreHopkins,
    animationOffset: -4,
    position: [36, 14],
  },
  {
    ...landingPageTalents.taylorHill,
    animationOffset: -10,
    position: [53, 11],
  },
  {
    ...landingPageTalents.usher,
    animationOffset: -1,
    position: [70, 9],
  },
  {
    ...landingPageTalents.disclosure,
    animationOffset: -14,
    position: [86, 15],
  },
  {
    ...landingPageTalents.idrisElba,
    animationOffset: -10,
    position: [4, 48],
  },
  {
    ...landingPageTalents.joeJonas,
    animationOffset: -2,
    position: [24, 56],
  },
  {
    ...landingPageTalents.lilyCollins,
    animationOffset: -10,
    position: [40, 50],
  },
  {
    ...landingPageTalents.stormReid,
    animationOffset: -13,
    position: [64, 48],
  },
  {
    ...landingPageTalents.lizzo,
    animationOffset: 0,
    position: [80, 52],
  },
];

export const landingPageHeroTalentsMobile: LandingPageHeroTalent[] = [
  {
    ...landingPageTalents.disclosure,
    position: [-6, -2],
    animationOffset: -12,
  },
  {
    ...landingPageTalents.gwenStefani,
    animationOffset: -3,
    position: [35, 10],
  },
  {
    ...landingPageTalents.deAndreHopkins,
    animationOffset: -10,
    position: [82, 0],
  },
  {
    ...landingPageTalents.idrisElba,
    animationOffset: -11,
    position: [36, 46],
  },
  {
    ...landingPageTalents.courteneyCox,
    animationOffset: 0,
    position: [-9, 42],
  },
  {
    ...landingPageTalents.lilyCollins,
    animationOffset: -2,
    position: [80, 40],
  },
  {
    ...landingPageTalents.joeJonas,
    animationOffset: -13,
    position: [-4, 83],
  },
  {
    ...landingPageTalents.taylorHill,
    animationOffset: -14,
    position: [80, 79],
  },
];

export const landingPageMarqueeTalents = {
  top: [
    landingPageTalents.stormReid,
    landingPageTalents.masonMount,
    landingPageTalents.matthewMcconaughey,
    landingPageTalents.disclosure,
    landingPageTalents.lizzo,
    landingPageTalents.oliviaPalermo,
    landingPageTalents.eltonJohn,
    landingPageTalents.charlesLeclerc,
    landingPageTalents.robGronkowski,
    landingPageTalents.jonasBrothers,
    landingPageTalents.taylorHill,
  ],
  bottom: [
    landingPageTalents.idrisElba,
    landingPageTalents.evaLongoria,
    landingPageTalents.lilyCollins,
    landingPageTalents.meghanTrainor,
    landingPageTalents.u2,
    landingPageTalents.addisonRae,
    landingPageTalents.farruko,
    landingPageTalents.usher,
    landingPageTalents.ashleyGraham,
    landingPageTalents.courteneyCox,
    landingPageTalents.jonathanVanNess,
  ],
};

export type LandingPageTalentCategoryName =
  | "Musicians"
  | "Actors"
  | "Athletes"
  | "Comedians"
  | "Influencers"
  | "Chefs"
  | "Podcasters"
  | "Brands"
  | "Models";

interface LandingPageTalentCategory {
  name: LandingPageTalentCategoryName;
}

export const landingPageTalentCategories: LandingPageTalentCategory[] = [
  { name: "Musicians" },
  { name: "Actors" },
  { name: "Athletes" },
  { name: "Comedians" },
  { name: "Influencers" },
  { name: "Chefs" },
  { name: "Podcasters" },
  { name: "Brands" },
  { name: "Models" },
];

export const landingPageFooterLinks = [
  { title: "Privacy & Cookies Policy", href: "https://komi.io/privacy-policy" },
  { title: "Terms and Conditions", href: "https://komi.io/talent-terms" },
  { title: "Media", href: "mailto:media@komi.io" },
  { title: "FAQ", href: "https://support.komi.io" },
  { title: "Contact Us", href: "mailto:support@komi.io" },
];

export type LandingPageSection = "Komi" | "Features" | "Creators" | "Join";
export const landingPageSections: LandingPageSection[] = ["Komi", "Features", "Creators", "Join"];

export const customizeThemeImgs = [
  { key: "light", imgSrcs: ["theme-light-1.webp", "theme-light-2.webp"] },
  { key: "dark", imgSrcs: ["theme-dark-1.webp", "theme-dark-2.webp"] },
  { key: "color", imgSrcs: ["theme-color-1.webp", "theme-color-2.webp"] },
];

export const creatorsScreenshots = {
  Musicians: "creators-musicians.webp",
  Actors: "creators-actors.webp",
  Athletes: "creators-athletes.webp",
  Comedians: "creators-comedians.webp",
  Influencers: "creators-influencers.webp",
  Chefs: "creators-chefs.webp",
  Podcasters: "creators-podcasters.webp",
  Brands: "creators-organizations.webp",
  Models: "creators-models.webp",
};
