import Cookies from "js-cookie";
import React from "react";

export default function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(() => {
    const persistedState = Cookies.get(key);
    return persistedState ? JSON.parse(persistedState) : defaultValue;
  });
  React.useEffect(() => {
    Cookies.set(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
}
