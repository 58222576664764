import { ConnectShopifyStoreModalControl } from "components/ConnectShopifyStoreModal";
import { komiTalentUrl } from "services/DomainService";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { User } from "redux/User/types";
import { LandingActionItem } from "types";
import { parseContent } from "utils/jwt";
import { logoutActions } from "redux/User/actions";
import { authService } from "services";

export enum LandingActions {
  CONNECT_SHOPIFY_STORE = "CONNECT_SHOPIFY_STORE",
  LOGOUT = "LOGOUT"
}

// TODO: registrable actions (a component can add it's own action for simpler dependency management)
export function useLandingAction(user: unknown, signin: VoidFunction) {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window === "undefined") return;

    // eslint-disable-next-line no-restricted-globals
    const urlParams = new URLSearchParams(location.search);

    const login = urlParams.has("signin") || urlParams.has("login");

    if (login && !user) return signin();

    const key = urlParams.get("action");

    if (!key) return;

    const action = actions[key as LandingActions];
    const helpers = {
      router,
      dispatch
    }

    if (action instanceof Function) {
      action(user, urlParams, helpers);
    } else if (action) {
      const { callback, auth } = action;

      auth && !user ? signin() : callback(user, urlParams, helpers);
    }
  }, [user]);
}

const actions: Record<LandingActions, LandingActionItem> = {
  CONNECT_SHOPIFY_STORE: {
    auth: true,
    async callback(user: unknown, urlParams: URLSearchParams) {
      const token = urlParams.get("session");
      const [tokenError, data] = parseContent(token);

      if (tokenError)
        // TODO: error notification
        return void console.error(tokenError);

      if (data) {
        const { dest: store, exp } = data;

        const [error, profile] = await ConnectShopifyStoreModalControl.open({
          store,
        });

        // TODO: expiration

        if (error)
          // TODO: error notification
          return void console.error(error);

        if (profile) {
          const { talent, isCollaborator } = profile;

          const options: any = {
            action: LandingActions.CONNECT_SHOPIFY_STORE,
            session: token,
          };

          if (isCollaborator) {
            // FIXME: user/talent/collaborator types
            options.talentProfileId = (talent as User)?.talentProfile?.id;
          }

          const params = new URLSearchParams(options);

          window.location.href = isCollaborator
            ? `${komiTalentUrl}/collaborator-login?${params.toString()}`
            : `${komiTalentUrl}/switch-to-talent?${params.toString()}`;
        }
      }
    },
  },
  async LOGOUT (_, __, { router, dispatch }) {
    dispatch(logoutActions.REQUEST());
    await authService.logout();
    router.push("/");
  }
};
