import selfless from "@komi-app/selfless";
import When from "@komi-app/when";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";

import LandingPageV2 from "components/LandingPageV2";
import Protect from "components/ReactAppProtect";
import TalentDetail from "components/TalentDetail";
import { SEGMENT_EVENT } from "constants/segment";

import useDeviceDetection from "hooks/useDeviceDetection";
import { useAnalytics } from "hooks/useAnalytics";
import usePage from "hooks/usePage";

import { useTypedSelector } from "redux/rootReducer";
import { getTalentBasicInfoActions } from "redux/Talent/actions";
import {
  selectTalent,
  selectTalentBasicInfo,
  selectTalentError,
  selectTalentModules,
} from "redux/Talent/selector";
import { Talent } from "redux/Talent/types";
import { TalentProfileModule } from "redux/User/types";
import { PAGE_TYPE } from "types";
import { AnalyticServices } from "utils/analytics";

const Page404 = dynamic(() => import("pages/404"));
const PageConsumerTerms = dynamic(() => import("pages/consumer-terms"));
const PageCreateProfile = dynamic(() => import("pages/create-profile"));
const PageForgotPassword = dynamic(() => import("pages/forgot-password"));
const PageGoogleAuthCallback = dynamic(() => import("pages/auth/callback/google"));
const PageInvitation = dynamic(() => import("pages/invitation"));
const PagePrivacyPolicy = dynamic(() => import("pages/privacy-policy"));
const PageResetPassword = dynamic(() => import("pages/reset-password"));
const PageTalentPrivacyPolicy = dynamic(() => import("pages/talent-privacy-policy"));
const PageTalentProfilePreview = dynamic(() => import("pages/talent-profile-preview"));
const PageTalentTerms = dynamic(() => import("pages/talent-terms"));
const PageTrial = dynamic(() => import("pages/trial"));
const PageVerifyEmail = dynamic(() => import("pages/sign-up/verify-email"))

function trackViewLandingPage() {
  const properties = {
    location: AnalyticServices.getLocation(),
    platform: AnalyticServices.getPlatform({ side: "client-side" }),
  } as const;

  AnalyticServices.track(SEGMENT_EVENT.VIEW_LANDING_PAGE, {
    Location: properties.location,
    Platform: properties.platform,
  });
  AnalyticServices.track(SEGMENT_EVENT.GA_VIEW_LANDING_PAGE, {
    is_ga_only: true,
    location: properties.location,
    platform: properties.platform,
  });
}

export type IProps = void;

export interface ISelf {
  Content: React.ComponentType<any> | (() => JSX.Element);
  isMobile: boolean;
  isMobileView: boolean;
  talentBasicInfo: Record<string, any>;
  talentStyle: Record<string, any>;
  talentView: ReactNode;
  type: PAGE_TYPE;
}

/*
  NOTE:

  This is to make up for the removal of next.
  Everything goes through this page on remote envs.
*/

const routes = {
  "/consumer-terms": PageConsumerTerms,
  "/create-profile": PageCreateProfile,
  "/forgot-password": PageForgotPassword,
  "/invitation": PageInvitation,
  "/privacy-policy": PagePrivacyPolicy,
  "/reset-password": PageResetPassword,
  "/talent-privacy-policy": PageTalentPrivacyPolicy,
  "/talent-profile-preview": PageTalentProfilePreview,
  "/talent-terms": PageTalentTerms,
  "/trial": PageTrial,
  "/sign-up/verify-email": PageVerifyEmail,
  "/auth/callback/google": PageGoogleAuthCallback,
  "/api/auth/callback/google": PageGoogleAuthCallback,
};

export function buildStyle(isMobile: boolean) {
  return {
    input: {
      width: "100%",
      borderRadius: "8px",
      height: "40px",
      padding: "0 16px",
      borderColor: "#121212",
      outline: "none",
    },
    button: {
      backgroundColor: "#121212",
      color: "white",
      border: "none",
      height: "40px",
      padding: "0 16px",
      borderRadius: "123px",
      cursor: "pointer",
      fontWeight: "600",
    },
    header: {
      border: "none",
      borderRadius: "8px 8px 0 0",
      fontSize: "18px",
      marginBottom: "8px",
    },
    wrapper: {
      border: "none",
      borderRadius: "8px",
      width: isMobile ? "calc(100% - 32px)" : "400px",
    },
  };
}

export function useClientSelf(): ISelf {
  const dispatch = useDispatch();
  const {
    type,
    username
  } = usePage(routes);

  const [banned, setBanned] = useState<boolean>();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isSentEvent, setSentEvent] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();

  const error = useTypedSelector(selectTalentError);
  const talent: Talent = useTypedSelector(selectTalent);
  const talentBasicInfo = useTypedSelector(selectTalentBasicInfo);
  const talentModules: TalentProfileModule[] = useTypedSelector(selectTalentModules);

  const talentStyle = buildStyle(isMobile);

  const { sendSegmentEvent } = useAnalytics();
  const isMobileView = useDeviceDetection();

  // /!\ this is ran on every re-render
  useEffect(() => {
    setIsMobile(
      !!navigator.userAgent.match(
        // Gist with mobile regex combinations: https://gist.github.com/dalethedeveloper/1503252#file-gistfile1-md
        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/,
      ),
    );
  });

  useEffect(() => {
    if (!isSentEvent && (window as any)?.analytics && type === PAGE_TYPE.HOMEPAGE) {
      setSentEvent(true);
      trackViewLandingPage();
    }
  }, [isSentEvent, type]);

  useEffect(() => {
    if (username && type === PAGE_TYPE.TALENT) {
      dispatch(getTalentBasicInfoActions.REQUEST(username));
    }
  }, [username, type]);

  useEffect(() => {
    if (!talent) return;

    if (error) {
      setBanned(true);
      setMessage(error);
    }
  }, [talent]);

  useEffect(() => {
    // @ts-ignore
    window.env = {
      SERVICE_NAME: process.env.NEXT_PUBLIC_SERVICE_NAME,
      SERVICE_VERSION: process.env.NEXT_PUBLIC_SERVICE_VERSION,
    };
  }, []);

  const talentView = useMemo(
    () => (
      <TalentDetail
        talent={talent}
        talentModules={talentModules}
        username={username}
        isMobile={isMobile}
        banned={banned}
        message={message}
      />
    ),
    [banned, isMobile, message, talent, talentModules, username],
  );

  const Content =
    type === PAGE_TYPE.CONTENT
      ? routes[window.location.pathname as keyof typeof routes]
      : () => <></>;

  return {
    Content,
    isMobile,
    isMobileView,
    talentBasicInfo,
    talentStyle,
    talentView,
    type,
  };
}

/*
  NOTE:

  This is currently used only locally
  as the remote environment don't use SSR.

  It will become obsolete as Next gets fully removed.
*/

export function useServerSelf(): ISelf {
  const type = PAGE_TYPE.SSR;
  const isMobile = false;
  const isMobileView = false;
  const talentBasicInfo = {};
  const talentStyle = buildStyle(isMobile);
  const talentView = null;

  return {
    Content: () => <></>,
    isMobile,
    isMobileView,
    talentBasicInfo,
    talentStyle,
    talentView,
    type,
  };
}

export const useSelf = typeof window === "undefined" ? useServerSelf : useClientSelf;

/*
  NOTE:

  This will eventually only render profiles
*/

export const View: React.FC<ISelf> = ({
  Content,
  isMobile,
  isMobileView,
  talentBasicInfo,
  talentStyle,
  talentView,
  type,
}) => (
  <When
    value={type}
    then={{
      [PAGE_TYPE.SSR]: <></>,
      [PAGE_TYPE.NOT_FOUND]: (
        <>
          <NextSeo title={"404 | This page could not be found"} />
          <Page404 />
        </>
      ),
      [PAGE_TYPE.CONTENT]: <Content />,
      [PAGE_TYPE.HOMEPAGE]: (
        <>
          <NextSeo
            title={"Komi"}
            description={
              "Komi is empowering the world’s leading talent to engage, own and monetize their fans"
            }
            openGraph={{
              title: "Komi",
              description:
                "Komi is empowering the world’s leading talent to engage, own and monetize their fans",
            }}
          />
          <LandingPageV2 isMobileDevice={isMobile} isMobile={isMobileView} />
        </>
      ),
      [PAGE_TYPE.TALENT]: (
        <React.Fragment>
          <Protect
            sha512={talentBasicInfo?.talentProfile?.hashedProtectionPassword}
            blur
            styles={talentStyle}
          >
            {talentView as JSX.Element}
          </Protect>
        </React.Fragment>
      ),
    }}
  />
);

export default selfless(View, useSelf);
