import { useMemo } from "react";
import { PAGE_TYPE } from "types";

export default function usePage (routes: Record<string, any>) {
  const hostname = useMemo(() => {
    return window.location ? window.location.hostname : "";
  }, [window]);

  const publicUrlHostname = useMemo(
    () => new URL(process.env.NEXT_PUBLIC_URL || "").hostname,
    [process.env.NEXT_PUBLIC_URL],
  );

  const isRootDomain = useMemo(() => hostname === publicUrlHostname, [hostname, publicUrlHostname]);
  const username = useMemo(() => hostname.split(".")[0], [hostname]);

  const type = useMemo(() => {
    if (isRootDomain || username === "consumer") {
      const { pathname } = window.location;

      if (pathname === "/") return PAGE_TYPE.HOMEPAGE;
      if (pathname in routes) return PAGE_TYPE.CONTENT;

      return PAGE_TYPE.NOT_FOUND;
    }

    return PAGE_TYPE.TALENT;
  }, [username]);

  return {
    type,
    username
  }
}