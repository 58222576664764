import React from "react";
import classNames from "classnames";

import { CtaButton } from "@komi-app/components";
import { HeroTalentBackground } from "./components/HeroTalentBackground";
import { ReactComponent as TitleTheUltimateCreatorHub } from "public/static/assets/landing-page/title-the-ultimate-creator-hub.svg";
import { ReactComponent as TitleTheUltimateCreatorHubMobile } from "public/static/assets/landing-page/title-the-ultimate-creator-hub-mobile.svg";

interface LandingSectionHeroProps {
  isMobileLayout: boolean;
  handleClickWaitlist: () => void;
}

export const LandingSectionHero: React.FC<LandingSectionHeroProps> = ({
  isMobileLayout,
  handleClickWaitlist,
}) => {
  return (
    <div
      className={classNames("landing-page-section-hero", {
        "landing-page-section-hero--mobile": isMobileLayout,
      })}
    >
      <div className="landing-page-section-hero__background">
        <HeroTalentBackground isMobile={isMobileLayout} />
      </div>

      <div className="landing-page-section-hero__content">
        <div> </div>

        <h1 className="landing-page-section-hero__content__heading">
          <span>The ultimate creator hub</span>
          {isMobileLayout ? <TitleTheUltimateCreatorHubMobile /> : <TitleTheUltimateCreatorHub />}
        </h1>

        <div className="landing-page-section-hero__content__cta">
          <CtaButton size={isMobileLayout ? "mobile" : "large"} onClick={handleClickWaitlist}>
            Join waitlist
          </CtaButton>
        </div>
      </div>
    </div>
  );
};
