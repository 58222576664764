import React from "react";
import { NextSeo } from "next-seo";

interface IProps {
  title?: string;
  description?: string;
  children: any;
  images?: any;
  hideDescription?: boolean;
}

const Page: React.FC<IProps> = ({
  title = "Komi",
  description = "Komi is empowering the world’s leading talent to engage, own and monetize their fans",
  children,
  images,
  hideDescription,
}) => {
  const titleData = title || "Komi";

  return (
    <>
      <NextSeo
        title={titleData}
        description={hideDescription ? undefined : description}
        openGraph={{
          images: images,
          title,
          description: hideDescription ? undefined : description,
        }}
      />
      {children}
    </>
  );
};

export default Page;
