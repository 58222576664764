import { Col } from "antd";
import Sider from "antd/lib/layout/Sider";
import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import classNames from "classnames";
import AvatarProfile from "components/AvatarProfile";
import { Paragraph, Text } from "components/Typography";
import { komiConsumerUrl, komiTalentUrl } from "services/DomainService";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactComponent as VideoIcon } from "public/static/assets/icons/video3.svg";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { MODAL_CREATE_ACCOUNT, MODAL_LOGIN, toggleModalActions } from "redux/Modal/actions";
import { selectModalData } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { joinFanClubActions, logoutActions, setUserValueAction } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import { User } from "redux/User/types";
import { ROLES } from "constants/auth";
import { authService } from "services";
import { getTalentName } from "utils/experience";
import { userHasRole } from "utils/user";
import { ReactComponent as CategoryIcon } from "../../../public/static/assets/icons/category.svg";
import { ReactComponent as CloseIcon } from "../../../public/static/assets/icons/close.svg";
import { ReactComponent as LogOutIcon } from "../../../public/static/assets/icons/logout.svg";
import { ReactComponent as PaymentIcon } from "../../../public/static/assets/icons/payment.svg";
import { ReactComponent as UserIcon } from "../../../public/static/assets/icons/user.svg";
import { ReactComponent as AddFillFullWidthIcon } from "../../../public/static/assets/icons/add-fill-full-width.svg";
import { RequestAProfileModal } from "components/DesktopMainMenu/RequestAProfileModal";

interface IProps {
  mobileMenuVisible: boolean;
  closeMenu(): void;
  showTalentProfileOnly?: boolean;
  isLandingPage?: boolean;
  handleClickWaitlist?: () => void;
  talent?: Talent;
}

const MobileMainMenu: React.FC<IProps> = ({
  mobileMenuVisible,
  closeMenu,
  showTalentProfileOnly,
  isLandingPage,
  handleClickWaitlist,
  talent,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const user: User = useTypedSelector(selectUserData);
  const loginModalData = useTypedSelector((state) => selectModalData(state, MODAL_LOGIN));
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));
  const [requestNewProfileModalIsOpen, setRequestNewProfileModalIsOpen] = useState(false);

  const toggleModalLogin = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: status,
          data: signUpModalData,
        }),
      );
    },
    [dispatch, signUpModalData],
  );

  const toggleModalCreateAccount = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: status,
          data: talent
            ? {
                ...loginModalData,
                callback: () => {
                  dispatch(
                    joinFanClubActions.REQUEST({
                      talentProfileId: talent?.talentProfile?.id,
                    }),
                  );
                },
              }
            : loginModalData,
        }),
      );
    },
    [dispatch, loginModalData, talent],
  );

  const handleLogout = useCallback(() => {
    closeMenu();
    dispatch(logoutActions.REQUEST());
    (window as any)?.analytics?.ready(function () {
      (window as any)?.analytics?.mixpanel?.cookie?.clear();
    });
    try {
      (window as any)?.FB?.api("/me/permissions", "delete", null, () =>
        (window as any)?.FB?.logout(),
      );
    } catch (error) {
      console.log();
    }
    if (router.asPath?.startsWith("/user/purchase-history/")) {
      router.push("/user/purchase-history/all");
    } else if (router.asPath?.startsWith("/user/special-requests/")) {
      router.push("/user/special-requests");
    }
  }, [closeMenu, dispatch, router]);

  // const handleSwitchToTalent = useCallback(() => {
  //   if (talentCredential) {
  //     window.open(`${komiTalentUrl}/switch-to-talent?token=${talentCredential}`, "_blank");
  //   }
  // }, [talentCredential]);

  const handleSwitchToTalent = useCallback(() => {
    if (typeof window !== "undefined") {
      window.open(`${komiTalentUrl}/switch-to-talent`, "_blank");
    }
  }, []);

  const handleGotoAccountSetting = useCallback(() => {
    if (!user?.talentProfile && !!user?.collaborationTalentProfiles?.length) {
      const collaborator = user.collaborationTalentProfiles[0];
      window.open(
        `${komiTalentUrl}/switch-to-talent?callbackUrl=/user/settings&talentProfileId=${collaborator?.talentProfile?.id}`,
        "_blank",
      );
      return;
    }
    window.open(`${komiTalentUrl}/switch-to-talent?callbackUrl=/user/settings`, "_blank");
  }, [user?.collaborationTalentProfiles, user?.talentProfile]);

  const handleGotoTalentByCollaborator = useCallback((collaborator: User) => {
    if (collaborator?.talentProfile?.id) {
      window.open(
        `${komiTalentUrl}/collaborator-login?talentProfileId=${collaborator.talentProfile.id}`,
        "_blank",
      );
    }
  }, []);

  const clickCreateProfile = useCallback(() => {
    window.open(`/create-profile`)
  }, []);

  return (
    <>
      <Sider
        collapsible
        collapsed={!mobileMenuVisible}
        collapsedWidth="0"
        className={classNames({
          "mobile-main-menu": true,
          "mobile-main-menu--talent": showTalentProfileOnly,
        })}
        width="100%"
        trigger={null}
      >
        <a
          className="mobile-main-menu__header"
          onClick={(e) => {
            e.stopPropagation();
            closeMenu();
          }}
        >
          <CloseIcon width={32} height={32} />
        </a>
        <Menu mode="inline" className={`p__t--24 p__x--${user ? 16 : 0}`} selectable={false}>
          {!user ? (
            <div className="m__t--48">
              {isLandingPage ? (
                <>
                  <Menu.Item
                    key="faq"
                    className="p__x--0 m__t--16 m__b--0"
                    onClick={(e) => {
                      closeMenu();
                      handleClickWaitlist?.();
                    }}
                  >
                    <Row align="middle" justify="space-between">
                      <Text preset="semibold16">Join Waitlist</Text>
                    </Row>
                  </Menu.Item>
                  <Menu.Item
                    key="login"
                    className="p__x--0 m__t--16 m__b--0"
                    onClick={(e) => {
                      closeMenu();
                      toggleModalCreateAccount(true);
                    }}
                  >
                    <Row align="middle" justify="space-between">
                      <Text preset="semibold16">Sign in</Text>
                    </Row>
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item
                    key="login"
                    className="p__l--40 m__t--24 m__b--0"
                    onClick={(e) => {
                      closeMenu();
                      toggleModalLogin(true);
                    }}
                  >
                    <Row align="middle" justify="space-between">
                      <Text preset="semibold16">{`Log In`}</Text>
                    </Row>
                  </Menu.Item>
                </>
              )}
            </div>
          ) : (
            <>
              <Menu.Item key="profile" disabled className="height--auto m__t--0 m__b--0">
                <Row justify="center" className="m__t--8">
                  <AvatarProfile
                    avatar={user.avatar || ""}
                    name={`${user.firstName} ${user.lastName}`}
                    avatarSize={64}
                  />
                </Row>
                <Row className="m__t--16" justify="center">
                  <Text
                    preset="semibold18"
                    className="text__align--center"
                  >{`${user.firstName} ${user.lastName}`}</Text>
                </Row>
              </Menu.Item>

              {!showTalentProfileOnly && (
                <>
                  <Menu.Item
                    key="account-information"
                    icon={<UserIcon width={24} height={24} />}
                    className={classNames({
                      active: router.asPath?.includes("/user/account-information"),
                      "m__t--32 m__b--0": true,
                    })}
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <Link href="/user/account-information">Account Information</Link>
                  </Menu.Item>

                  <Menu.Item
                    key="purchase-history"
                    icon={<PaymentIcon width={24} height={24} />}
                    className={classNames({
                      active: router.asPath?.includes("/user/purchase-history"),
                      "m__t--16 m__b--0": true,
                    })}
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <Link href="/user/purchase-history/all">Payment history</Link>
                  </Menu.Item>

                  <Menu.Item
                    key="my-experiences"
                    icon={<CategoryIcon width={24} height={24} />}
                    className={classNames({
                      active: router.asPath?.includes("/user/my-experiences"),
                      "m__t--16 m__b--0": true,
                    })}
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <Link href="/user/my-experiences/upcoming">Upcoming</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="my-videos"
                    icon={<VideoIcon width={24} height={24} />}
                    className={classNames({
                      active: router.asPath?.includes("/user/videos"),
                      "m__t--16 m__b--0": true,
                    })}
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <Link href="/user/videos">My Videos</Link>
                  </Menu.Item>
                </>
              )}

              {window.location.origin === komiConsumerUrl && showTalentProfileOnly && (
                <>
                  <Paragraph
                    preset="semibold14"
                    className="p__x--20 p__t--16 p__b--8 m__t--16 text--default opacity--06"
                  >
                    Connected Profiles
                  </Paragraph>
                </>
              )}

              {window.location.origin === komiConsumerUrl && user?.talentProfile && (
                <Menu.Item
                  key={getTalentName(user, true)}
                  icon={
                    <AvatarProfile
                      avatarSize={24}
                      avatar={user?.talentProfile?.avatar || ""}
                      name={getTalentName(user, true)}
                    />
                  }
                  onClick={handleSwitchToTalent}
                  className="m__b--0"
                >
                  <Text
                    className={showTalentProfileOnly ? "m__l--8 truncate" : "m__l--16 truncate"}
                    preset="semibold16"
                  >
                    {getTalentName(user, true)}
                  </Text>
                </Menu.Item>
              )}

              {window.location.origin === komiConsumerUrl &&
                user?.collaborationTalentProfiles?.map((collaborator, index) => (
                  <Menu.Item
                    key={index}
                    icon={
                      <AvatarProfile
                        avatarSize={24}
                        avatar={collaborator.talentProfile?.avatar || ""}
                        name={getTalentName(collaborator, true)}
                      />
                    }
                    onClick={() => handleGotoTalentByCollaborator(collaborator)}
                  >
                    <Text
                      className={showTalentProfileOnly ? "m__l--8 truncate" : "m__l--16 truncate"}
                      preset="medium16"
                    >
                      {getTalentName(collaborator, true)}
                    </Text>
                  </Menu.Item>
                ))}

              {userHasRole(user, ROLES.SELFSIGN) ? (
                <Menu.Item
                  key={"/create-profile"}
                  onClick={clickCreateProfile}
                  className="large"
                >
                  <Row align="middle">
                    <Col className="d--flex align__items--center">
                      <AddFillFullWidthIcon className="no-stroke" style={{ margin: 0 }} />
                    </Col>

                    <Col>
                      <div className="p__l--8 d--flex flex__direction--column">
                        <Text preset="semibold16">Create a New Profile</Text>
                        <Text preset="semibold14" className="opacity--06">
                          30-day trial available
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </Menu.Item>
              ) : null}

              {userHasRole(user, ROLES.TALENT) ? (
                <Menu.Item
                  key="request-profile"
                  onClick={() => {
                    setRequestNewProfileModalIsOpen(true);
                    closeMenu();
                  }}
                >
                  <Row align="middle">
                    <Col className="d--flex align__items--center">
                      <AddFillFullWidthIcon className="no-stroke" style={{ margin: 0 }} />
                    </Col>

                    <Col className="p__l--8">
                      <Text preset="semibold16">Request a New Profile</Text>
                    </Col>
                  </Row>
                </Menu.Item>
              ) : null}

              {showTalentProfileOnly ? (
                <>
                  <Paragraph
                    preset="semibold14"
                    className="p__x--20 p__t--16 p__b--8 text--default opacity--06"
                  >
                    Account
                  </Paragraph>

                  <Menu.Item key="/user/settings" onClick={handleGotoAccountSetting}>
                    <Text preset="semibold16">Account Information</Text>
                  </Menu.Item>

                  <Menu.Item key="logout" onClick={handleLogout}>
                    <Text preset="semibold16">Log Out</Text>
                  </Menu.Item>
                </>
              ) : (
                <Menu.Item
                  key="logout"
                  className="m__t--16 m__b--0 text--red"
                  icon={<LogOutIcon width={24} height={24} />}
                  onClick={handleLogout}
                >
                  <Text preset="semibold16" className="text--red">
                    Logout
                  </Text>
                </Menu.Item>
              )}
            </>
          )}
          <Menu.Item disabled className="h--100"></Menu.Item>
        </Menu>
      </Sider>

      <RequestAProfileModal
        visible={requestNewProfileModalIsOpen}
        onCancel={() => setRequestNewProfileModalIsOpen(false)}
        onOk={() => setRequestNewProfileModalIsOpen(false)}
      />
    </>
  );
};

export default MobileMainMenu;
