import Avatar from "antd/lib/avatar";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import classNames from "classnames";
import ImageSkeleton from "components/ImageSkeleton";
import { Text } from "components/Typography";
import React, { useMemo } from "react";

interface IProps {
  avatar: string;
  avatarText?: string;
  name: string;
  profession?: string;
  showInfo?: boolean;
  avatarSize?: number;
  textSize?: number;
  textClassName?: string;
  theme?: "light" | "dark";
  onClick?: () => void;
  shape?: "circle" | "square";
}

const AvatarProfile = ({
  avatar,
  avatarText,
  name,
  profession,
  showInfo,
  avatarSize,
  textSize = 16,
  textClassName,
  theme,
  onClick,
  shape = "circle",
}: IProps) => {
  const avatarName = useMemo(() => {
    const value = avatarText || name;
    if (value) {
      const elements = value.split(" ");
      return elements.length > 0
        ? elements.map((item) => (item.length ? item[0].toUpperCase() : "")).join("")
        : value[0];
    }
    return "";
  }, [name, avatarText]);

  return (
    <Row
      gutter={showInfo ? 8 : 0}
      align="middle"
      className={`avatar-profile ${onClick ? "cursor-pointer" : ""}`}
      onClick={onClick}
      wrap={false}
    >
      <Col flex="none">
        {avatar ? (
          <ImageSkeleton
            shape={shape}
            src={avatar}
            size={avatarSize || 56}
            width={avatarSize || 56}
            height={avatarSize || 56}
          />
        ) : (
          <Avatar className="avatar-profile__text" shape="circle" size={avatarSize || 56} alt={avatarName}>
            {avatarName}
          </Avatar>
        )}
      </Col>

      {showInfo && (
        <Col flex={1} style={{ minWidth: 0 }}>
          <Row className="avatar-profile__info" align="middle">
            <Text
              // @ts-ignore
              preset={`regular${textSize}`}
              className={classNames("full-width", textClassName, theme === "dark" ? "text--white" : "text--black")}
              ellipsis
            >
              {name}
            </Text>
          </Row>

          {profession && (
            <Row className="avatar-profile__info" align="middle">
              <Text
                className={classNames(
                  "avatar-profile__profession opacity--08",
                  theme === "dark" ? "text--white" : "text--black",
                )}
              >
                {profession}
              </Text>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};

export default AvatarProfile;
