import React from "react";
import Atropos from "atropos/react";
import classNames from "classnames";

interface Hover3DImageStackProps {
  images: { src: string; offset: number; className?: string }[];
  alt: string;
}

export const Hover3DImageStack: React.FC<Hover3DImageStackProps> = ({ images, alt }) => {
  return (
    <Atropos
      shadow={false}
      highlight={false}
      rotateXMax={5}
      rotateYMax={5}
      activeOffset={5}
      rotateTouch={false}
    >
      {images.map((image, i) => (
        <img
          key={image.src}
          src={image.src}
          data-atropos-offset={image.offset}
          alt={i > 0 ? "" : alt}
          className={classNames({ "atropos-stacked-item": i > 0 }, image.className)}
          aria-hidden={i > 0}
        />
      ))}
    </Atropos>
  );
};
