import { reject } from "lodash";

export const getExtension = (filename: string) => {
  if (!filename) return;
  const parts = filename.split(".");
  return parts[parts.length - 1];
};

export const isImage = (filename: string) => {
  const ext = getExtension(filename);
  if (!ext) return true;

  switch (ext?.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "png":
    case "webp":
      //etc
      return true;
  }
  return false;
};

export const isVideo = (filename: string) => {
  const ext = getExtension(filename);
  if (!ext) return;

  switch (ext?.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
    case "quicktime":
      // etc
      return true;
  }
  return false;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};
function validateDownloadSpeedParams(baseUrl: string, fileSizeInBytes: number) {
  if (typeof baseUrl !== "string") {
    throw new Error("baseUrl must be a string");
  }
  if (typeof fileSizeInBytes !== "number") {
    throw new Error("fileSizeInBytes must be a number");
  }
  return;
}

export function checkDownloadSpeed(baseUrl: string, fileSizeInBytes: number) {
  validateDownloadSpeedParams(baseUrl, fileSizeInBytes);
  return new Promise((resolve, reject) => {
    const download = new Image();
    download.onload = function () {
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000;
      // Convert bytes into bits by multiplying with 8
      const bitsLoaded = fileSizeInBytes * 8;
      const bps: number = parseFloat((bitsLoaded / duration).toFixed(2));
      const kbps = parseFloat((bps / 1024).toFixed(2));
      const mbps = parseFloat((kbps / 1024).toFixed(2));
      resolve({ bps, kbps, mbps });
    };
    download.onerror = function (err, msg) {
      reject(err);
    };

    const startTime = new Date().getTime();
    const cacheBuster = "?nnn=" + startTime;
    download.src = baseUrl + cacheBuster;
  }).catch((error) => {
    throw new Error(error);
  });
}
