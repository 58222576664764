export const roomUrlFromPageUrl = () => {
  const match = window?.location?.search?.match(/roomUrl=([^&]+)/i);
  return match && match[1] ? decodeURIComponent(match[1]) : null;
};

export const pageUrlFromRoomUrl = (roomUrl: string) => {
  return window.location.href.split("?")[0] + (roomUrl ? `?roomUrl=${encodeURIComponent(roomUrl)}` : "");
};

export const toQuery = (params: { [x: string]: any }, delimiter = "&") => {
  const keys = Object.keys(params);

  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[key]}`;

    if (index < keys.length - 1) {
      query += delimiter;
    }

    return query;
  }, "");
};

export const getJsonFromUrl = (url: string) => {
  if (!url && process.browser) url = window.location.search;
  const query = url.substr(1);
  const result: any = {};
  query.split("&").forEach(function (part) {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const isValidHttpUrl = (str: string) => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
