import React from "react";
import { Layout } from "antd";
import { Footer, PillNav } from "@komi-app/components";

import {
  landingPageFooterLinks,
  LandingPageSection,
  landingPageSections,
} from "constants/landing-page";
import Page from "components/Page";
import LogginWrapper from "components/LogginWrapper";
import ConnectShopifyStoreModal from "components/ConnectShopifyStoreModal";
import LandingPageV2Header from "./LandingPageV2Header";
import { ReactComponent as Instagram } from "public/static/assets/landing-page/instagram.svg";
import { ReactComponent as LinkedIn } from "public/static/assets/landing-page/linkedin.svg";

interface LandingPageV2LayoutProps {
  children?: React.ReactNode;
  handleClickWaitlist?: () => void;
  isMobile: boolean;
  title?: string;
  description?: string;
  images?: any;
  hideHeader?: boolean;
  hideFooter?: boolean;
  activeSection: string;
  setSelectedSection: (section: LandingPageSection) => void;
}

export const LandingPageV2Layout: React.FC<LandingPageV2LayoutProps> = ({
  children,
  handleClickWaitlist,
  isMobile,
  title,
  description,
  images,
  hideHeader = false,
  hideFooter = false,
  activeSection,
  setSelectedSection,
}) => {
  return (
    <Layout className="landing-page-v2-layout">
      <Page title={title} description={description} images={images}>
        <div className="landing-page-v2-layout__background-gradient" />

        <LogginWrapper isLandingPage />

        <ConnectShopifyStoreModal />

        <LandingPageV2Header
          hide={hideHeader}
          isMobile={isMobile}
          handleClickWaitlist={handleClickWaitlist}
          onClickLogo={() => setSelectedSection("Komi")}
        />

        {children}

        {!hideFooter ? (
          <div className="landing-page-v2-layout__footer-container">
            <Footer
              showLogo={true}
              socials={[
                {
                  name: "instagram",
                  url: "https://www.instagram.com/my.komi",
                  icon: <Instagram />,
                },
                {
                  name: "linkedin",
                  url: "https://www.linkedin.com/company/komii",
                  icon: <LinkedIn />,
                },
              ]}
              sections={[
                {
                  title: "Info",
                  content: (
                    <>
                      {landingPageFooterLinks.map((link) => (
                        <p key={link.title}>
                          <a href={link.href} target="_blank" rel="noopener noreferrer">
                            {link.title}
                          </a>
                        </p>
                      ))}
                    </>
                  ),
                },
                {
                  title: "London",
                  content: (
                    <>
                      <div>Argyll Street,</div>
                      <div>Soho, London, W1F 7TE</div>
                    </>
                  ),
                },
                {
                  title: "Los_Angeles",
                  content: (
                    <>
                      <div>9000 Sunset Blvd,</div>
                      <div>West Hollywood, CA 90069</div>
                    </>
                  ),
                },
              ]}
            />
          </div>
        ) : null}

        <div className="landing-page-v2-layout__nav-container">
          <PillNav
            value={activeSection}
            options={landingPageSections}
            isMobile={isMobile}
            onChange={(newSection) => setSelectedSection(newSection as LandingPageSection)}
          />
        </div>
      </Page>
    </Layout>
  );
};
