import Skeleton from "antd/lib/skeleton";
import React, { useState, useEffect, useCallback } from "react";
import { transformImageSize } from "utils/photo";
import { ReactComponent as EditIcon } from "public/static/assets/icons/edit.svg";
import { Row } from "antd";

interface IProps {
  src: string;
  imageClassName?: string;
  skeletonClassName?: string;
  shape?: "square" | "circle";
  size?: number | "small" | "large" | "default" | undefined;
  width?: number;
  height?: number;
  borderRadius?: number;
  onClick?: () => void;
  showEdit?: boolean;
  style?: any;
  useOriginSize?: boolean;
}

const ImageSkeleton: React.FC<IProps> = ({
  src,
  imageClassName = "",
  skeletonClassName = "",
  shape = "square",
  width = 24,
  height = 24,
  borderRadius = 0,
  onClick = () => null,
  showEdit,
  style = {},
  useOriginSize = false,
}) => {
  const [isLoaded, setLoaded] = useState(false);
  const imgEl: any = React.useRef(null);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const onError = useCallback(() => {
    setLoaded(false);
  }, []);

  useEffect(() => {
    if (imgEl.current?.complete) {
      onLoad();
    }
  }, [onLoad]);

  return (
    <div
      className={`image-skeleton image-skeleton--${shape} ${isLoaded && "loaded"} position--relative`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      onClick={onClick}
    >
      {src && (
        <img
          ref={imgEl}
          className={isLoaded ? imageClassName : "d--none"}
          src={transformImageSize(src, Math.round(width), Math.round(height), false, useOriginSize)}
          alt="Komi"
          width={width}
          height={height}
          // loading="lazy"
          onError={onError}
          onLoad={onLoad}
          style={{ borderRadius: `${borderRadius}px`, ...style }}
        />
      )}
      <Skeleton.Avatar
        className={isLoaded && !!src ? "d--none" : skeletonClassName}
        active={!isLoaded && !!src}
        shape={shape}
        size={width}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          borderRadius: `${borderRadius}px`,
        }}
      />
      {showEdit ? (
        <Row className="image-skeleton__mobile-edit" align="middle" justify="center">
          <EditIcon />
        </Row>
      ) : null}
    </div>
  );
};

export default ImageSkeleton;
