import notification from "utils/notification";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { awsService, photoService } from "services";
import { PresignedPostData } from "services/externals/AWSService";
import { isVideo, getExtension, isImage } from "./file";
import { getJsonFromUrl } from "./url";

export const onBeforeUpload = (file: RcFile, checkAvatar?: boolean) => {
  const limitSize = isImage(file.name) ? file.size / 1024 / 1024 < 5 : file.size / 1024 / 1024 < 1024 * 15;

  if (!limitSize && isImage(file.name)) {
    notification.error({ message: "Image must smaller than 5MB!" });
  }
  if (!limitSize && isVideo(file.name)) {
    notification.error({ message: "Video must smaller than 15GB!" });
  }
  if (checkAvatar && isVideo(file.name)) {
    notification.error({ message: "Can not use Video for avatar" });
  }

  if (checkAvatar) return limitSize && !isVideo(file.name);
  return limitSize;
};

export const handleCreatePresignedUrl = async (file: UploadFile<any>): Promise<PresignedPostData | any> => {
  if (!file.name) return;

  const fileName = file.name;
  const fileExtension = getExtension(fileName)?.toLowerCase() as string;
  const contentType = file.type;

  let result: any;

  if (isVideo(fileName)) {
    result = await photoService.getPresignedVideoUrl(fileExtension);
  } else if (contentType) {
    result = await photoService.getPresignedPhotoUrl(fileExtension, contentType);
  }

  if (result && !result.ok) {
    throw new Error(result.message);
  } else return result.response;
};

export const pushImageToS3 = async (
  file: UploadFile<any>,
  callbackFn?: (value: number) => void,
): Promise<string | undefined> => {
  try {
    const presignedData = await handleCreatePresignedUrl(file);
    if (isVideo(file.name)) return await awsService.uploadVideoToS3(presignedData, file.originFileObj, callbackFn);
    return await awsService.uploadPhotoToS3(presignedData, file.originFileObj, callbackFn);
  } catch (e) {
    notification.error({
      message: (e as Error).message,
      description: "",
    });
  }
};

export const transformImageSize = (
  url: string,
  width: number,
  height: number,
  withBlur = false,
  useOriginSize = false,
) => {
  const devUrl = "https://images.komi.io/dev";
  const stagingUrl = "https://images.komi.io/staging";
  const prodUrl = "https://images.komi.io/production";

  let cdnUrl = null;

  if (url?.startsWith("https://komi-assets")) {
    cdnUrl = devUrl;
  } else if (url?.startsWith("https://komi-staging-assets")) {
    cdnUrl = stagingUrl;
  } else if (url?.startsWith("https://komi-production-assets")) {
    cdnUrl = prodUrl;
  }

  if (cdnUrl) {
    const startAt = url.split("/", 3).join("/").length;
    const match = /https:\/\/.+\.(s3\.amazonaws|s3-accelerate\.amazonaws)\.com\//gim.test(url);
    if (!match) {
      return url;
    }

    const jsonURL = getJsonFromUrl(new URL(url)?.search);

    if (useOriginSize || jsonURL?.tr) {
      return `${cdnUrl}${url.substring(startAt)}`;
    }
    const res = `${cdnUrl}${url.substring(startAt)}?tr=h-${height * 1.5}%2Cw-${width * 1.5},f-auto`;

    return withBlur ? `${res},bl-6` : res;
  }

  return url;
};

export const getImageOrFallback = (path: string, fallback: string | null) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(path);
    img.onerror = () => resolve(fallback);
  });
};

export const loadXHR = (url: string) => {
  return new Promise(function (resolve, reject) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function () {
        reject("Network error.");
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject("Loading error:" + xhr.statusText);
        }
      };
      xhr.send();
    } catch (err) {
      reject(err);
    }
  });
};
